@import '../../css/base';

.my-bookmarks-page {
    .my-bookmarks-main-pane {
        margin-bottom: $content-margin-bottom;
        
        .product-list {
            margin-top: 20px;
            margin-bottom: 40px;
            padding: 0px 10px 0px 10px;

            .product-summary-table{
                margin-top: 10px;
                margin-bottom: 10px;

                .product-summary-row {
                    border-radius: 6px;
                    margin-bottom: 20px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    @include product-shadow;

                    padding: 8px;
                    display: flex;
    
                    .product-brief-name {
                        color: $link-color;
                        text-decoration: underline;;
                    }
                    .product-brief-paragraph {
                        margin-top: 14px;
                        margin-bottom: 14px;
                        line-height: 1.3;
                    }
                    .product-thumbnail {
                        min-width: 48px;
                        max-width: 128px;
                        width: 20vw;
                        padding-right: 10px;
                        box-sizing: border-box;
                    }
                }
            }

            .multiline-field-label-text{
                padding-top: 7px;
            }
            .multiline-field-label{
                padding-top: 5px;
            }
        }

    }
}