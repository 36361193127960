@import '../../css/base';

.shopping-payment {
    min-height: 100vh;

    .action-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
        margin-top: 40px;

        .ubutton {
            min-width: 50vw;
        } 
    }
    .shopping-payment-content {
        margin-bottom: $content-margin-bottom;
        
        .warning-message {
            background-color: rgb(255, 244, 229);
            color: rgb(102, 60, 0);
            padding: 1em 1em 1em 1em;
        }
    }
}