@import '../../../css/base';

$avatar-margin-top: 30px;
$avatar-size: 50vw;
$green-part-height: calc(50vw * 0.8 + 30px);
$avatar-max-size: 256px;
$green-part-max-height: calc(256px * 0.8 + 30px);

@mixin section-margins {
    margin-left: 10px;
    margin-right: 10px;
}
@mixin icon-size {
    width: 18px;
    height: 18px;
}

.nutritionist-profile-portait {
    width: 100%;
    .nutritionist-profile-portait-content {
        width: 100%;
        margin-bottom: $content-margin-bottom;
        
        .avatar-section {
            border-top: 1px solid rgb(214, 214, 214);
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            padding-bottom: 20px;

            .nutritionist-avatar{
                margin-top: $avatar-margin-top;
                width: $avatar-size;
                height: $avatar-size;

                max-width: $avatar-max-size;
                max-height: $avatar-max-size;
            }
            .green-part, .white-part {
                position: absolute;
            }
    
            .green-part {
                width: 100%;
                height:  $green-part-height;
                max-height: $green-part-max-height; 
                background-color: $green2;
            }    
        }

        .name-title {
            margin-left: 10px;
            margin-right: 20px;

            .nutritionist-name {
                font-weight: bold;
                color: $acpn_green1;
                padding-bottom: 0.5rem;
                font-size: 1.2rem;
            }
            .titles {
                font-size: $font14px;
                font-style: italic;
                padding-bottom: 0.5rem;
            }
            border-bottom: 1px solid gray;
        }

        .simple-note.status-section{
            margin-top: 15px;
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 1rem;
            color: red;

            svg {
                @include icon-size();
                fill: red;
            }
        }

        .institution {
            margin-top: 15px;
            @include section-margins;
            font-family: 'Times New Roman', Times, serif;
        }

        .expertice {
            margin-top: 15px;
            @include section-margins;
            // color: darken($color1, 40%);
            color: black;
            font-size: 1.1rem;
        }

        .testimonial-section {
            padding-left: 10px;
            padding-right: 10px;
            >p{
                text-align: center;
                font-weight: bolder;
            }
            .testimonial {
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 10px;
                // background-color: #B6DFE5;
                background-color: #DFDBD2;
                border-radius: 8px;
    
    
                .paragraph {
                    line-height: 1.8;
                    font-weight: lighter;
                }
                .client-name {
                    padding-top: 20px;
                    color: $gray3;
                    padding-bottom: 8px;
                }
                .client-info {
                    padding-bottom: 8px;
                    font-style: italic;
                    color: $gray3;
                    font-size: 0.9rem;
                }
            }
        }

        .gender-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;
            align-items: center;

            .gender-icon {
                @include icon-size;
            }
        }

        .phone-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;
            align-items: center;

            .phone-icon {
                @include icon-size;
            }
        }
        .email-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;
            align-items: center;

            .email-icon {
                @include icon-size;
            }
            .simple-email{
                font-size: 1rem;
            }
        }

        .language-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;
            align-items: center;

            .language-icon {
                @include icon-size;                
            }
        }

        .address-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .address-icon {
                @include icon-size;
            }
            .address {
                flex-grow: 1;
                background-color: $address-bg;
                padding-left: 5px;
                border-radius: 6px;
                border: 1px solid rgb(230, 230, 230);

                .address-line {
                    line-height: 1.5;
                }    
            }
        }
        .website-section{
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .website-icon {
                @include icon-size;
            }
        }
        .social-media-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .social-media-icon {
                @include icon-size;
            }
        }
        .service-time-section{
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .service-time-icon {
                @include icon-size;
            }

            .service-time-rec {
                padding: 5px;
                margin-bottom: 1rem;
                background-color: $address-bg;
                border-radius: 6px;
                border: 1px solid rgb(230, 230, 230);
                
                .tag-section {
                    display: flex;
                    column-gap: 10px;
                    .tag {
                        padding: 4px;
                        border: 1px solid gray;
                        border-radius: 10px;
                        color: $google-green;
                    }
                }
                .service-time-part.note{
                    line-height: 2;
                }
                .service-time-part{
                    padding-bottom: 5px;
                }
            }
        }
        .qrcode-section{
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .qrcode-icon {
                @include icon-size;
            }
        }
        .license-section{
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .license-icon {
                @include icon-size;
            }
            .property-list-widget .property-name {
                font-weight: normal;
            }
        }
        .insurance-section {
            display: flex;
            column-gap: 12px;
            margin-top: 15px;
            @include section-margins;

            .insurance-icon {
                @include icon-size;
            }
            .label {
                padding: 0px 0px 10px 0px;
            }
            .insurance-number {
                font-family: monospace;
            }
        }


        .resume-section {
            padding-top: 6px;
            .resume{
                padding: 6px;
                border: 1px solid $gray6;
                border-radius: 6px;
                margin-top: 10px;  
                margin-bottom: 10px;  
            }
        }
    }
}

.nutritionist-qrcode-dialog{
    #simple-dialog-title h2{
        text-align: center;
        color: $acpn_green1;
        font-weight: bolder;
    }
    .error-dialog-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nutritionist-qr-code-img{
            max-width: 100%;
        }
    }
}

.license-image-dialog {
    .MuiDialog-paper {
        margin-left: 0px;
        margin-right: 0px;
    }
    .MuiDialogContent-root {
        padding: 0px;
    }
    .nutritionist-license-img {
        max-width: calc(100%);
    }
}

.service-time-rec {
    padding: 5px;
    margin-bottom: 1rem;
    background-color: $address-bg#b5d1f82d;
    border-radius: 6px;
    border: 1px solid rgb(230, 230, 230);
    
    .tag-section {
        display: flex;
        column-gap: 10px;
        .tag {
            padding: 4px;
            border: 1px solid gray;
            border-radius: 10px;
            color: $google-green;
        }
    }
    .service-time-part.note{
        line-height: 2;
    }
    .service-time-part{
        padding-bottom: 5px;
    }
}

