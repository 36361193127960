@import '../../css/base';

.shopping-product-brief-wrapper{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 8px;

    .shopping-product-brief {
        display: flex;
        flex-direction: column;
        align-items: center;

        box-sizing: border-box;
        height: 100%;
        border: 1px solid rgb(230, 230, 230);  
        border-radius: 6px;  
        padding: 4px;    
        background-color: white;
        
        @include product-shadow;

        .thumbnail-img-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            box-sizing: border-box;
            padding: 20px 20px 0px 20px;

            .thumb-img {
                width: 100%;
            }
        }

        .product-description {
            overflow: hidden;
            box-sizing: border-box;
            padding-top: 15px;
        }

        .read-more-button {
            margin-top: 20px;
            margin-bottom: 20px;
            border-radius: 5px;
            border: 0.5px solid rgb(200, 200, 200);
            font-size: $font14px;
            color: rgb(131, 131, 131);
        }

        .product-name{
            margin-top: 20px;
            font-size: $font14px;
            padding-left: 10px;
            font-weight: bold;
        } 
        .product-price {
            margin-top: 10px;
            font-weight: bold;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: $font13px;
            color: $price-color;
        }           
    }    
} 

