@import '../../css/base';

.transfer-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .notice {
        width: 120px;
        padding: 10px 10px 10px 10px;
        margin: auto;
    }

    .transfer-record-list {
        margin-top: 20px;
        margin-bottom: $content-margin-bottom;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        width: 100%;

        .property-list-widget {
            background-color: $paper-gray;
        }
        .transfer-record {
            // border: 1px solid rgb(187, 187, 187);
            margin-bottom: 20px;

            .debt {
                color: #c70000;
                font-weight: bold;
            }
            .credit {
                font-weight: bold;
            }
        }

    }
}