@import '../../css/base';

.shop-order-commissions{   
    .shop-order-commission-main-pane{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: $content-margin-bottom;

        .MuiGrid-item{
            padding: 5px 5px;

            .date-label {
                padding-top: 0.4rem;
            }
            .status-label {
                padding-top: 0.6rem;
            }

            .start-date, .end-date {
                width: 100%;
            }
        }

        .status-select {
            width: 100%;
        }

        .date-range{
            margin-top: 10px;
            label {
                font-size: 0.9rem;
            }
        }
        .commision-status-option {
            display: flex;
            align-items: center;

            .MuiFormControl-root {
                width: 155px;
            }
        }

        .shop-order-commission{
            margin-bottom: 30px;

            font-size: $font14px;   
            font-family: Roboto;
            
            .price {
                font-weight: bold;
                color: $price-color;
            }
            .prod-thumb-img > img{
                width: 50px;
            }

            .property-list-widget {
                @include product-shadow;
            }
        }
    }
}