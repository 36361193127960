@import './css/base';

$animation_duration:0.5s;
$animation_curve:ease;

body {
    font-size: 1rem; // fix default font size
    background-color: white;
    // background-color: $paper-gray; // default background of a page.
}
.app-page {
    -webkit-overflow-scrolling: touch;
}

a {
    color: $link-color;
}

.redirect-message {
    padding-top: 20em;
    width: 10em;
    color: lightgray;
    margin: auto;
}

.open-url-message {
    font-size: $font12px;
    font-weight: normal;
    white-space: pre-wrap;
    word-wrap: break-word;
}