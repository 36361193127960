@import '../../css/base';

.set-nutritionist {
    .set-nutritionist-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        width: 100%;
        padding-top: 1em;
        margin-bottom: $content-margin-bottom;

        .nutritionist-brief {
            width: calc(100vw - 30px);
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
            margin-bottom: 1em;
    
            .green-check-icon{
                color: green;
                font-weight: bolder;
                font-size: 1.25rem;
            }
        }
    }
}