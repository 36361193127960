@import '../../css/base';

.product-category-tree {
    .product-category-tree-node {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $gray6;
    }

    .product-category-tree-node.dir{
        display: flex;
        justify-content: space-between;
    }
    .product-category-tree-node.in-path .label{
        color: $google-green;
    }
}