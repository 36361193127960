@import '../../css/base';

.tools-page {
    .tools-menu-list {
        margin-bottom: $header-footer-height;

        .tools-menu {
            width: 100%;
            margin-bottom: 10px;
            border-radius: 0px;
            position: relative;
            height: 60px;

            .my-profile-icon {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 5px;
                left: 20px;
            }    
            .my-profile-label {
                position: absolute;
                top: 15px;
                left: 20px;
                font-size: $font20px;
            }
            .chevron-right-icon {
                position: absolute;
                top: 15px;
                right: 20px;
            }
        }

        .tools-menu.contract-icon > img.my-profile-icon {
            width: 36px !important;
            height: 36px !important;
            margin-left: 5px;
        }

        .logout-button {
            margin-top: 20px;
            width: 90%;
        }
    }
}

.tools-page.en {
    .tools-menu-list {
        .tools-menu {
            .my-profile-label {
                font-size: $font16px;
            }
        }
    }
}