.points-view {
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;

    .points-label {
       margin-right: 10px;
    }
    .points-number {
        color: black;
        font-weight: bold;
        font-size: 1.4rem;
    }

    .refresh-points-icon {
        color: rgb(221, 144, 0);
        float: right;
    }
}