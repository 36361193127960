.simple-note {
    padding: 8px 12px;
    box-sizing: border-box;
    display: inline-block;
}
  
.simple-note.error {
    background-color: #ffdddd;
    border-left: 6px solid #f44336;
  }
  
.simple-note.success {
    background-color: #ddffdd;
    border-left: 6px solid #04AA6D;
  }
  
.simple-note.info {
    background-color: #e7f3fe;
    border-left: 6px solid #2196F3;
  }
  
  
.simple-note.warn {
    background-color: #ffffcc;
    border-left: 6px solid #ffeb3b;
  }
