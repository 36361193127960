.error-dialog{
    .MuiPaper-root.MuiDialog-paper{
        min-width: 50%;
        max-height: 80%;
    }
    .MuiDialogTitle-root {
        min-width: 60%;
        color: rgb(184, 0, 9);
    }
    .MuiDialogActions-root{
        display: flex;
        justify-content: center;
        border-top: 1px solid rgb(214, 214, 214);
    }
    .error-dialog-body {
        word-wrap: break-word;
        // overflow-y: scroll;
    }    
}
