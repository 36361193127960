.points-input-view {
    .points-input {
        width: 100%;
    }

    .input-error-message {
        font-size: 0.8rem;
        color: #b71c1c;
        padding-bottom: 4px;
    }
}
