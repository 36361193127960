@import '../../css/base';

.unishell-pay-page {
    width: 100%;

    .unishell-pay-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        margin-bottom: $content-margin-bottom;
    
        .points-view, .payment-order {
            width: 100%;
        }

        .payment-order {
            td.label {
                padding-left: 5px;
                padding-right: 0px !important;
            }
        }

        .password-input-view {
            padding-top: 20px;
            width: 90%;

            .password-input {
                width: 100%;
            }
        
        }

        .input-error-message {
            font-size: 80%;
            color: #b71c1c;
            padding-bottom: 4px;
        }

        button {
            min-width: 10em;
            margin-top: 40px;
        }
    }
}