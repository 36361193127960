@import '../../css/base';

.shopping-checkout{
    width: 100%;

    .shopping-checkout-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $content-margin-bottom;

        width: 100%;
        box-sizing: border-box;
        padding: 10px 6px 0px 6px;

        .MuiAccordion-root {
            width: 100%;
            margin-bottom: 8px;

            .MuiAccordionSummary-content{
                display: flex;
                align-items: center;
    
                .accordion-label{
                    margin-left: 1em;
                }
                .done-icon {
                    width: 18px;
                    height: 18px;
                    fill: green;
                }
            }

            .MuiAccordionDetails-root {
                display: block;
            }
        }

        .billing-address-accordion {
            table {
                font-size: 0.9rem;
                tr {
                    td {
                        border-top: 1px solid rgba(0, 0, 0, 0.30);
                        padding-top: 10px;
                    }
                }
            }
            .create-billing-address-actions {
                display: flex;
                justify-content: space-around;
            }
            .create-billing-address-header {
                text-align: center;
                margin-top: 0px;
                font-weight: bold;
                font-size: 0.9rem;
            }
            .billing-address-actions{
                margin-top: 10px;
                display: flex;
                justify-content: space-evenly;
            }
        }

        .shipping-address-accordion{
            .pagination-select.select-pickup-location{
                background-color: $address-bg;
                border-radius: 8px;
                padding: 4px;

                .selected {
                    .item {
                        // font-weight: bolder;
                        line-height: 1.7;
                    }
                }
                .pagination-item {
                    .item {
                        line-height: 1.7;
                    }
                }
            }


            .shipping-method-pickup{
                font-size: 0.9rem;

                table {
                    tbody {
                        td {
                            padding-top: 10px;
                        }
                    }
                }
            }
            .shipping-method-courier{
                .header {
                    font-weight: bold;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    text-align: center;
                }
                .create-shipping-address-actions{
                    display: flex;
                    justify-content: space-around;
                }
                table {
                    margin-top: 5px;
                    border-collapse: collapse;
                    tbody {
                        td {
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            padding-top: 5px;
                        }
                       
                    }
                }

                .shipping-address-actions{
                    border-top: 1px solid rgba(0, 0, 0, 0.3);
                    margin-top: 5px;
                    margin-bottom: 5px;
                    padding-top: 5px;
                    display: flex;
                    justify-content: space-around;
                }
                .chinese-id-photos{
                    display: flex;
                    justify-content: space-around;
                    margin-top: 5px;
                    .image-with-overlay {
                        width: 35%;
                        .the-image {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .payment-method-accordion {
            .wechat-pay, .ali-pay {
                display: flex;
                align-items: center;

                padding-top: 10px;

                .not-supported {
                    font-size: 0.8rem;
                }
            }
            .support-payment-false {
                img {
                    filter: gray; /* IE6-9 */
                    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
                    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
                }
            }
            .payment-method-logo {
                display: inline-block;
                height: 28px;
                margin-right: 5px;
            }

            .current-points{
                display: flex;
                justify-content: space-between;
                column-gap: 20px;
                align-items: center;
                padding-left: 1rem;
            }

            .unis-payment-method {
                .line {
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                }

                font-size: 0.9rem;
                a {
                    font-weight: bold;
                    color:rgb(0, 66, 173);
                    text-decoration: underline;
                    margin-left: 10px;
                }

                .unis-points-discount {
                    font-weight: bold;
                    color: #8B0000;
                }
            }
        }
        .MuiAccordionSummary-content.Mui-expanded{
            .accordion-label{
                font-weight: bold;
                font-size: 1.2rem;
            }
        }

        button#new-billing-address-button {
            margin-bottom: 1em;
        }

        .checkout-actions {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;

            display: flex;
            justify-content: space-around;
        }

        .confirm-order-accordion {
            .MuiAccordionDetails-root{
                padding-left: 8px;
                padding-right: 8px;
            }
            .shopping-cart-health-advice{
                border: 1px solid rgba($color: $button-primary , $alpha: 0.3);
                border-radius: 6px;
                .shopping-cart-product {
                    border: unset;
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                }
            }
            .shopping-cart-product {
                padding: 8px;
                border: 1px solid rgba($color: $button-primary , $alpha: 0.3);
                border-radius: 6px;
                margin-bottom: 8px;
            }
        }
    }
}