.recipient-input-view {
    .recipient-input {
        width: 100%;
        input{
            font-family: monospace !important;
            font-size: 0.8rem;
        }
    }
    .input-error-message {
        font-size: 0.8rem;
        color: #b71c1c;
        padding-bottom: 4px;
    }
}
