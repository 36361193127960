@import '../../css/base';

$category-color: rgb(90, 90, 90);

.simple-shop-search-form {
    // background-color: $header-green;
    // background: linear-gradient(90deg, rgba(52,168,83,1) 0%, rgba(52,208,94,1) 45%, rgba(52,168,83,1) 100%);
    // background: linear-gradient(90deg, rgba(1,181,109,1) 0%, rgba(2,201,122,1) 49%, rgba(1,181,109,1) 100%);
    // background: linear-gradient(90deg, rgba(0,129,97,1) 0%, rgba(0,153,115,1) 49%, rgba(0,129,97,1) 100%);
    // background: linear-gradient(90deg, rgba(31,125,44,1) 0%, rgba(42,162,58,1) 49%, rgba(31,125,44,1) 100%);
    background: $search-form-background;

    .keywords-input-wrapper {
        width: 100%;

        .filter-icon {
            fill: white;
            width: 24px;
            height: 24px;
            flex-grow: 0;
        }
        .shopping-product-search {
            flex-grow: 1;
        }
    }
    .keywords-input-wrapper.with-category {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
}

// rendered under root element directly
.categories-wrapper {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 20px 20px 50px 20px;
    background-color: rgba(0,0,0,0.2);
    z-index: 3;
    overflow: scroll;

    .categories {
        border-radius: 5px;
        background-color: rgb(245, 245, 245);
        padding: 20px 20px 20px 20px;
        color: rgb(47, 47, 47);

        .header {
            font-weight: bold;
            font-size: $font20px;
            text-align: center;
            padding-bottom: 20px;
            border-bottom: 1px solid gray;
        }
        .close {
            padding-top: 20px;
            text-align: right;
        }
        #close-icon {
            position: absolute;
            fill: $close-color;
            top: 40px;
            right: 30px;
            width: 20px;
            height: 20px;
        }
    }
}
