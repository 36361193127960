@import '../../css/base';

.shopping-address-form {
    .MuiFormControl-root {
        width: 100%;
    }  
    .id-photo {
        padding-bottom: 10px;

        .image-with-overlay.id-image img{
            max-width: 100%;
            max-height: calc(90vw);
            box-sizing: border-box;
        }
        .image-with-overlay.id-image.transformed img{
            border: 2px dashed orangered;
        }
        button{
            margin-right: 1em;
        }
        button:nth-child(2){
            margin-left: 1em;
        }
        .note {
            padding-top: 5px;
            padding-bottom: 10px;
            font-weight: bold;
        }
    } 

    .upload-buttons{
        margin-top: 15px;
    }

    label#id-front-image-label,  label#id-back-image-label {
        padding: 4px 8px 4px 8px;
        background-color: $button-primary;
        color: white;
        border-radius: 4px;
    }

    .error-msg {
        margin-bottom: 8px;
        color: rgb(184, 0, 9);
    }
}