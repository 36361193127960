@import '../../css/base';

.edit-licence-page {
    .edit-nutritionist-content {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: $content-margin-bottom;

        display: flex;
        flex-direction: column;
        align-items: center;

        .name, .email {
            text-align: center;
        }
        .nutritionist-licence-form {
            background-color: white;
        }

        .save-btn {
            width: 50%;
            flex-grow: 0;
            margin-top: 20px;
        }
    }
}