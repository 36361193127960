@import '../../css/base';

.shopping-health-advice-by-me {
    width: 100%;
}
.shopping-health-advice-by-me-main-pane {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: $content-margin-bottom;
    
    .client-name {
        text-align: center;
        font-weight: bolder;
    }
    .pagination-list {
        width: 100%;

        .health-advice-list {
            width: 100%;
        }

        .create-advice-for-the-client {
            padding-top: 4px;
        }
    }
}

.health-advice-readonly {
    width: 100%;
    margin-bottom: 1em;
    @include product-shadow;

    .product-name {
        color: rgb(0, 0, 238);
        text-decoration: underline;
    }
    .key-ingredients-paragraph{
        font-size: 0.9rem;
    }
    .property-value.cancel-button{
        display: flex;
        justify-content: center;
    }
}

.shopping-health-advice-by-me-main-pane-search-form-wrapper{
    background: $search-form-background;
    padding: 10px;
}