@import '../../css/base';

.h-equal-size {
    display: flex;
    flex-wrap: nowrap;

    .h-equal-size-child {
        flex-basis: 0px;
        flex-grow: 1;
        overflow: hidden;
        display: inline-block;
    }
}