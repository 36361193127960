@import '../../css/base';

.shopping-page {
    width: 100%;

    #init-message {
        width: 100%;
        padding-top: calc(50vh - 10px);
        text-align: center;
        font-weight: bold;
        color:gray;
        font-size: $font18px;
    }

    #init-error-message {
        width: 100%;
        padding-top: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .message {
            color:red;
            margin: 20px 20px 20px 20px;
            overflow-wrap: anywhere;
        }
        button {
            margin-top: 20px;
        }
    }
}