@import '../../css/base';

.shopping-nutritionist-profile{
    width: 100%;
    
    .shopping-nutritionist-profile-content{
        width: 100%;
        box-sizing: border-box;
        padding: 12px;
        
        margin-bottom: $content-margin-bottom;
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-with-overlay.qr-code {
            width: 50%;
            max-width: 128px;

            img {
                max-width: 100%;
            }
        }
        > p {
           font-weight: bold;
        }
        .nutritionist-detail{
            width: calc(100% - 40px);
        }        
        .edit-nutritionist {
            width: calc( 100% - 40px );
        }        
    }
}