@import '../../css/base';

.markdown-editor {
    position: relative;
    .title {
        // font-weight: bolder;
        // font-size: 1.2rem;
        // border-bottom: 1px solid $gray4;
    }
    .edit-icon {
        width: 20px;
        height: 20px;

        position: absolute;
        top: 0px;
        right: 0px;
    }
    .source-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0px;
        right: 40px;
    }

    .source-icon.active {
        background-color: black;
        fill:white;
    }
    .markdown-source{
        width: 100%;
        box-sizing: border-box;
        height: fit-content;
    }
}