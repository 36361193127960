@import '../../css/base';

.shopping-cart-page {
    width: 100%;

    .shopping-cart{
        padding: 10px 10px 10px 10px;
        width: 100%;
        box-sizing: border-box;
        font-size: $font14px;
        margin-bottom: $content-margin-bottom;


        .shopping-cart-actions {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }

}