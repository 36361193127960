@import '../css/_base.scss';

.unishell-page {
    // margin-bottom: $footer-height;
    // width: 100%;
    // overflow-x: hidden;
    // box-sizing: border-box;

    #init-message {
        width: 100%;
        height: calc(100vh - 75.6px - 20px);
        text-align: center;
        font-weight: bold;
        color:gray;
        font-size: $font18px;
        padding-top: 20px;
    }
    
    #init-error-message {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .message {
            color:red;
            margin: 20px 20px 20px 20px;
            overflow-wrap: anywhere;
        }
        button {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }    

    .unishell-page-content {
        // prevent top and footer bars from moving around when content is wider than viewport
        width: 100%;
        overflow-x: hidden;   
    }
}
