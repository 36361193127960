@import '../../css/base';

.credit-card-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    min-height: calc(100vh - 80px);
    background-color: rgba(0, 0, 0, 0.03);

    .header {
        text-align: center;
    }

    table.order-info {
        width: calc(100vw - 40px);
        tbody {
            tr {
                td {
                    padding: 12px 10px 12px 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                }
                .label {
                    font-weight: bold;
                }
                td:nth-child(1){
                    min-width: 3em;
                }
                .amount {
                    font-weight: bold;
                    color: $price-color;
                }
            }
        }
    }

    .action-buttons {
        width: 100%;
        margin-top: 20px;
        display: flex;
        

        justify-content:space-around;
    }

    .payment-success {
        width: calc(100vw - 40px);
    }

    .stripe-wrapper {
        width: 80%;
        margin-top: 20px;

        .stripe-form {
            label {
                margin-left: 1em;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .StripeElement{
                box-shadow: 0 1px 3px 0 #a4a4a4;
                margin-top: 10px !important;
                margin-bottom: 10px !important;
                padding-bottom: 0px !important;

                .__PrivateStripeElement {
                    margin-top: 5px !important;
                }
            }

            .confirm-purchase-button, cancel-purchase-button {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: center; 
               
               button {
                   margin-top: 20px;
                   margin-bottom: 20px;
               }
            }
        }
    }    
}