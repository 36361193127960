@import '../../css/base';

.shopping-product-detail {
    width: 100%;
    
    .health-advice{
        display: flex;
        flex-direction: column;
        align-items: center;    
        padding-top: 20px;
        padding-left: 10px;
        margin-bottom: $content-margin-bottom;

        .health-advice-message {
            font-weight: bold;
            font-size: $font14px;
        }
    }

    .actions-wrapper {
        display: flex;
        justify-content: center;
    }

    .add-to-shopping-cart{
        display: flex;
        align-items: center;
        column-gap: 10px;
        flex-wrap: wrap;
        row-gap: 20px;

        margin-top: 20px;
        margin-bottom: $content-margin-bottom;
        margin-left: 10px;

        .MuiSvgIcon-root {
            border: 1px solid gray;
            border-radius: 4px;
            fill: rgb(59, 59, 59);
        }

        label {
            font-weight: bold;
        }
        input {
            width: 2em;
            border-radius: 4px;
            padding: 3px 3px;
            border:1px solid rgba(0, 0, 0, 0.3);
        }
        button{
            // margin-right: 10px;
            margin-left: 10px;
        }
    }


    .shopping-product-detail-main-pane {
        .health-advice, .add-to-shopping-cart {
            margin-bottom: 20px;
            margin-left: 0px;
        }
    }
}
