@import '../../css/base';

.u-date-picker {
    width: 100%;

    .MuiOutlinedInput-root {
        color: black;

        .MuiOutlinedInput-input {
            padding: 10px;
        }
    }    
}

.u-pick-date-dialog {
    .MuiDialogActions-root.MuiDialogActions-spacing{
        padding: 0px;
    
        button {
            padding: 0px 8px;
        }
    }
}

.u-date-picker2-overlay{
    position: fixed;
    top: 0px; bottom: 0px; left: 0px; right: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;

    padding: 20px 10px;
    box-sizing: border-box;

    .u-date-picker2 {
        width: 100%;
        padding: 10px 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: white;

        display: flex;
        flex-direction: column;

        h5 {
            margin-top: 0px;
            margin-bottom: 5px;
            text-align: center;
        }
        .display-day-of-week {
            margin-bottom: 10px !important;
            border-bottom: 1px solid gray;
        }
        .date-input, .month-input, .year-input {
            display: inline-block;
            min-width: 4rem;
            margin-left: 5px;
            border-width: 1px;
            border-radius: 4px;
            border: 1px solid rgb(204, 204, 204);
            padding: 4px;
            min-height: 16px;
        }

        .year-actions, .month-actions, .date-actions {
            margin-bottom: 5px;
            margin-top: 5px;
            display: flex;
            column-gap: 15px;
            padding-left: 1.4rem;

            .delta{
                min-width: 1rem;
                border: 1px solid gray;
                border-radius: 4px;
                padding-left: 6px;
                padding-right: 6px;
            }
        }
        .year-actions, .month-actions{
            margin-bottom: 20px;
        }

        .action-buttons {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid gray;
        }
    }
}

input.u-date-picker2-text {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgb(182, 182, 182);
    box-sizing: border-box;
}

div.u-date-picker2-text {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgb(182, 182, 182);
    box-sizing: border-box;
    min-height: calc(16px + 1rem);
    min-width: 1rem;
}

input.u-date-picker2-text:focus {
    outline: none;
}