@import '../../css/base';


.shopping-order-list{   
    .shopping-order-list-content{
        padding: 10px 10px 10px 10px;
        margin-bottom: 38px;
    }

    .shopping-order-search-form {
        margin-bottom: 10px;

        .keyword {
            width: 100%;
        }

        .MuiGrid-item {
            padding: 10px 10px;
        }
        .status-select {
            width: 100%;
        }

        .last-n-days-select {
            width: 100%;
        }
        .search-button {
            min-width: 40%;
            margin-top: 10px;
            margin-left: 30%;
        }
    }

    .shopping-order-list-table {
        width: 100%;
        margin-bottom: 1em;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-collapse: collapse;

        font-size: $font14px;   
        font-family: Roboto;

        @include product-shadow;

        .order-id {
            font-weight: bold;
            font-family: 'Courier New', Courier, monospace;
        }
        .total-price {
            font-weight: bold;
            color: $price-color;
        }
        .prod-thumb-img > img{
            width: 50px;
        }
    }
}

.shopping-order-list-search-order-form {
    padding: 10px;
    box-sizing: border-box;
    background: $search-form-background;

    .keyword-input {
        display: flex;
        column-gap: 20px;
        align-items: center;
    }
    .shopping-product-search {
        flex-grow: 1;
    }
    .filter-icon {
        width: 24px;
        height: 24px;
        fill: white;
    }

    .MuiGrid-item {
        padding: 10px;
        box-sizing: border-box;
    }
    .search-order-filter {
        label {
            display: inline-block;
            color: white;
            margin-bottom: 4px;
        }

        .status-select {
            width: 100%;
            background-color: white;
            border-radius: 4px;
            overflow: hidden;
        }

        .date-range {
            width: 100%;
            .u-date-picker .MuiOutlinedInput-root {
                background-color: white;

                input {
                    outline: unset;
                }
            }

            .u-date-picker2-text {
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
}