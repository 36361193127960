@import '../../css/base';

.shopping-my-client-list {
    width: 100%;

    .shopping-search-client-main-pane {
        padding: 10px 10px 10px 10px;
        margin-bottom: $content-margin-bottom;

        .shopping-product-search {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        .client-of-nutritionist{
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
            margin-bottom: 1em;
            @include product-shadow;

            .property-name {
                font-weight: bold;
            }

            .create-health-advice{
                font-weight: bold;
            }

            .action-buttons {
                padding: 6px 8px 6px 8px;
                display: flex;
                // justify-content: center;
                flex-wrap: wrap;
                row-gap: 20px;

                .create-health-advice{
                  font-weight: bold;
                }
            }
    
        }

        .nutritionist-client-form {
            margin-top: 20px;
        }
        .edit-client-buttons {
            display: flex;
            margin-top: 20px;
            justify-content: space-around;

        }
    }
}

.client-of-nutritionist.inactive{
    color: gray !important;
//    background-color: rgba(211, 211, 211, 0.664) !important; 
}


.shopping-search-client-main-pane-client-search-form-wrapper {
    background: $search-form-background;
    padding: 10px;
}