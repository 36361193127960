@font-face{
  font-family: fanzhenliukai;
  src: local('fanzhenliukai'), url('./fonts/fanzhen-liukai.ttf') format('truetype');
}


// Numeric keyword values
/*
font-weight: 100;
font-weight: 200;
font-weight: 300;
font-weight: 400;// normal
font-weight: 500;
font-weight: 600;
font-weight: 700;// bold
font-weight: 800;
font-weight: 900;
*/
// https://fonts.google.com/specimen/Montserrat
@font-face{
  font-family: montserrat;
  font-weight: lighter;
  font-style: normal;
  src: url('./fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}
@font-face{
  font-family: montserrat;
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face{
  font-family: montserrat;
  font-weight: bold;
  font-style: normal;
  src: url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face{
  font-family: montserrat-thin;
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
}


body {
  // margin-top: 0px !important;
//   font-family:Roboto, Helvetica, Arial, sans-serif;
  Font-family: 'montserrat', 'Helvetica Neue', 'Heiti SC', 
               Roboto, Helvetica, Tahoma, Arial,
               STXihei, “华文细黑”, SimSun, “宋体”, Heiti, “黑体”, sans-serif, “Microsoft YaHei”, “微软雅黑”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
