@import '../../../css/base';

.pickup-location-content {
    padding: 10px;
    margin-bottom: $content-margin-bottom;

    .property-list-widget {
        background-color: $paper-gray;
    }

    .simple-note {
        width: 100%;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .device-properties {
        margin-top: 10px;
    }

    .upgrade-info {
        padding: 2px;
        border: 1px solid $gray6;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .download-address textarea {
        width: calc(100% - 6px);
        border: unset;
        background-color: $paper-gray;
        // box-sizing: border-box;
        // border: 0.5px solid white;
    }
    .download-address textarea:focus {
        outline: unset;
        // border: 0.5px solid $link-color;
        // border-radius: 6px;
        background-color: $gray3;
        color: white;
    }
}