@import '../../css/base';

.shopping-page-size-selector{
    // font-size: $font13px;
    position: relative;
    display: inline-block;

    select {
        border-radius: 4px;
        background-color: white;
    }

    .shopping-page-size-label {
        padding-right: 0.5rem;
    }
}