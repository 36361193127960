@import '../../css/base';

.unis-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .header {
        text-align: center;
    }

    .points-view {
        width:100%;
    }

    table.order-info {
        width: calc(100vw - 40px);
        tbody {
            tr {
                td {
                    padding: 12px 10px 12px 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                }
                .label {
                    font-weight: bold;
                }
                td:nth-child(1){
                    min-width: 3em;
                }
                .amount {
                    font-weight: bold;
                    color: $price-color;
                }
            }
        }
    }

    .action-buttons {
        width: 100%;
        margin-top: 20px;
        display: flex;
        
        justify-content:space-around;

    }

    .payment-success {
        width: calc(100vw - 40px);
    }
}