@import '../../css/base';

.payment-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .notice {
        width: 120px;
        padding: 10px 10px 10px 10px;
        margin: auto;
    }

    .payment-order-list {
        margin-top: 20px;
        margin-bottom: $content-margin-bottom;
        width: calc( 100vw - 40px );

        .list-wrapper{
            margin-top: 10px;
        }

        .payment-order {
            // border: 1px solid rgb(187, 187, 187);
            margin-bottom: 20px;

            .debt {
                color: #c70000;
                font-weight: bold;
            }
            .credit {
                font-weight: bold;
            }
            td {
                padding-top: 0px;
                padding-bottom: 0px;
            }

            td:first-child {
                width: 4.5em;
                padding-right: 0px !important;
                padding-left: 5px !important;
            }
        }

        .payment-order.odd {
            // background-color: rgba(128, 128, 128, 0.096);
        }

    }
}