@import '../../css/base';

.receive-view {
    width: 100%;

    hr {
        width: 100%;
    }

    
    .address-panels {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: $content-margin-bottom;

        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        box-sizing: border-box;

        .receive-view-prompt1 {
            text-align: center;
        }
        .user-name {
            padding: 5px 0px 0px 0px;
            text-align: center;
        }
        .receive-view-qrcode-image {
            width: 80%;
            img {
                width: 100%;
                height: auto;
            }
        }
        .receive-view-prompt2 {
            padding: 10px 0px 10px 10px;
            text-align: center;
        }
        
        .receive-view-recipient-addr {
            width: 100%;
            text-align: center;
            font-family: monospace;
            margin-bottom: 10px;

            #receive-view-addr-textarea {
                width: 100%;
                border-radius: 6px;
            }
        }
        button {
            width: 10em;
        }

    }
}

.receive-view.en{
    .address-panels button {
        width: 100%;
    }
}
