$menu-tree-background-color: rgba(255, 255, 255, 0);
$menu-pane-background-color:  white;
$menu-item-border-color: rgb(184, 184, 184);
$active-menu-color:rgb(63, 63, 63);
$inactive-menu-color:gray;

$root-anim-time: 0.5s;

.unishell-tree-menu-root {
    position: fixed;
    height: calc(100vh); /* we want to keep full height so that animation is smooth */
    left: 0px; 
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    transition: top $root-anim-time;
    z-index: 4;
    overflow: auto;

    .close-icon{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        fill: red;
    }

    .unishell-tree-menu-pane {
        background-color: $menu-tree-background-color;
        margin-top: 42px;
        margin-left: 15px;
        position: relative;

        color:rgb(59, 59, 59);

        .menu-dir{
            padding: 15px 10px 10px 10px;
            background-color: $menu-pane-background-color;
            position: absolute;
            left: 10px;
            top: 0px;
            min-width: 40%;
            border: 1px solid rgba(0, 0, 0, 0.2);
            transition: left 0.5s;
        }
        .menu-dir.level1, .menu-dir.level2, .menu-dir.level3 {
            left: calc(0px - 40%);
        }

        // .menu-dir.level0{
        //     box-shadow: -4px 0px 5px 0px rgb(182, 182, 182);
        // }

        .menu-dir.level1.slide{
            top: 6ch;
            left: 6ch;
            box-shadow: -4px 0px 5px 0px rgb(182, 182, 182);
        }

        .menu-dir.level2.slide{
            left: 12ch;
            box-shadow: -4px 0px 5px 0px gray;
        }
        .menu-dir.level3.slide{
            left: 18ch;
            box-shadow: -4px 0px 5px 0px gray;
        }

        .menu-dir.slide-out {
            left: calc(0px - 50vw);
        }

        .menu-item {
            padding: 15px 10px 15px 10px;
            border-top: 1px solid $menu-item-border-color;
        }
        .menu-item.select{
            background-color: darken($menu-pane-background-color, 7%);
        }
        .menu-item.dir{
            display: flex;
            justify-content: space-between;
        }

        .menu-dir-title {
            padding: 10px 0px 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            color: rgb(77, 77, 77);

            display:flex;
            align-items: center;

            .back-icon {
                width: 18px;
                margin-right: 2ch;
                fill: $active-menu-color;
            }
        }

        .menu-dir.inactive {
            color:$inactive-menu-color;

            .menu-dir-title{
                color:$inactive-menu-color;
                
                .back-icon{
                    fill:  $inactive-menu-color;
                }
            }
        }
    }
}

.unishell-tree-menu-root.close, .unishell-tree-menu-root.open.first-time-open {
    top: calc(0px - 105vh); 
    transition: top $root-anim-time;
}
.unishell-tree-menu-root.open.regular-open {
    top: 0px;
    transition: top $root-anim-time;
}