.pagination-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    // .shopping-page-size-selector{
    //     position: absolute;
    //     top: 8px;
    //     right: 10px;
    // }

    .data-list-wrapper{
        width: 100%; // default
        box-sizing: border-box;
    }
    .pagination-list-footer0{
        text-align: center;
        margin-top: 10px;
        span {
            margin-left: 5px;
        }
    }
    .pagination-list-footer0.top{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        column-gap: 5px;
        padding-right: 10px;

        .shopping-page-size-selector{
            flex-grow: 0;
        }
        .page-count {
            flex-grow: 1;
        }
    }
    .clickable {
        text-decoration: underline;
    }

    .pagination-list-footer{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;

        span.refresh {
            margin-left: 20px;
            margin-right: 20px;
        }
        span.clickable.disabled {
            color: rgba(128, 128, 128, 0.7);
            text-decoration: unset;
        }
    }
    .back-to-top {
        border-width: 0px;
    }
}