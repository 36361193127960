@import '../../css/base';

.purchase-points-page {
    min-height: 100vh;

    .purchase-points-steps {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%;

        padding-bottom: $content-margin-bottom;

        .purchase-points-step {     
            width: 100%;       
            display: flex;
            flex-direction: column;
            align-items: center;

            .simple-note{
                width: 90%;
                font-weight: bold;
                color: rgb(59, 59, 59);
                margin-bottom: 20px;
            }

            .label {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;
                font-weight: bolder;
                svg {
                    fill: green;
                    width: 20px;
                    height: 20px;
                }
            }

            .label.sub-label {
                padding-top: 10px;
                margin-bottom: 20px;
            }

            .payment-method-img {
                height: 18px;
            }
        }
        .purchase-points-step-1 {
            .MuiTextField-root {
                width: 100%;
            }
            .MuiGrid-item {
                padding: 10px 5px;
            }
            .points-error {
                color: red;
            }
        }
        .payment-method-radio-group {
            margin-top: 20px;
            margin-bottom: 10px;
            .payment-method-radio {
                display: flex;
                align-items: center;
                margin-bottom: 13px;
    
                img {
                    height: 30px;
                } 
            }    
            .payment-method-radio.disabled {
                img {
                    filter: gray; /* IE6-9 */
                    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
                    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
                }
                .not-supported{
                    font-size: 0.8rem;
                }
            }
        }

        .confirm-points-button, .confirm-purchase-button, .cancel-purchase-button, .return-purchase-button {
            margin-top: 30px;
            button {
                min-width: calc(60vw);
            }
        }
        // adjust braintree drop in display
        #dropin-container {
            margin-top: 10px !important;
            .braintree-heading[data-braintree-id="choose-a-way-to-pay"] {
                display: none;
            }
        }

        .braintree-option.braintree-option__card {
            .braintree-option__label::before {
                content: '信用';
            }
        }

        .powered-by {
            margin-top: 3em;
            font-size: $font12px;
            text-align: center;
        }

        .purchase-points-step-20{
            .label {
                width: unset;
                font-size: $font18px;
                font-weight: bold;
            }

            .MuiPaper-root {
                width: 100%;
            }

            .notice {
                font-size: $font14px;
            }

            .MuiPaper-root {
                margin-top: 20px;
            }

            table tr td {
                padding-left: 5px;
                padding-right: 5px;
            }
            table tr td:nth-child(1) {
                box-sizing: border-box;
                width: 75px;
            }

            td.order-code {
                font-family: monospace;
            }
        }
    }

    .stripe-wrapper {
        box-sizing: border-box;
        width: 100%;
        margin-top: 20px;

        .stripe-form {
            label {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .StripeElement{
                width: 100%;
                box-sizing: border-box;
                box-shadow: 0 1px 3px 0 #a4a4a4;
                margin-top: 10px !important;
                margin-bottom: 10px !important;
                padding-bottom: 0px !important;

                .__PrivateStripeElement {
                    margin-top: 5px !important;
                    width: 100%;
                }
            }

            .confirm-purchase-button, cancel-purchase-button {
               width: 100%;
               display: flex;
               flex-direction: column;
               align-items: center; 
               
               button {
                   margin-top: 20px;
               }
            }
        }

    }

    .payment-method-radio-group {
        .payment-method-radio-alipay, .payment-method-radio-wechatpay {
            img {
                max-width: 20vw;
            }    
        }
        .payment-method-radio.payment-method-radio-stripe{
            img {
                max-width: 70%;
                height: auto;
            }
        }
    }
    .purchase-points-step.purchase-points-step-20{
        .property-list-widget{
            margin-top: 20px;
        }
    }

    .purchase-points-steps.xxs, .purchase-points-steps.xxxs{
    }
}