@import '../../css/base';


.pharmacy-dashboard{
   
    .pharmacy-dashboard-content{
        padding: 10px 10px 10px 10px;
        margin-bottom: $content-margin-bottom;

        .pharmacy-info {
            p{
                text-align: center;
            }
        }
    }

    .pharmacy-dashboard-table {
        width: 100%;
        background-color: $paper-gray;
        margin-bottom: 1em;
        @include product-shadow;

        .order-id {
            font-weight: bold;
            font-family: 'Courier New', Courier, monospace;
        }
        .total-price {
            font-weight: bold;
            color: $price-color;
        }
        .prod-thumb-img > img{
            max-width: 50px;
        }
        .status-PAID {
            display: inline-block;
            color:green;
            margin-right: 10px;
            border-radius: 3px;
            font-weight: bold;
        }
        .status-READY_FOR_PICKUP {
            display: inline-block;
            color:purple;
            margin-right: 10px;
            border-radius: 3px;
            font-weight: bold;
        }
        .status-PICKED_UP{
            display: inline-block;
            color: brown;
            margin-right: 10px;
            border-radius: 3px;
            font-weight: bold;
        }

    }
}

.pharmacy-dashboard-content-shopping-order-search-form {
    background: $search-form-background;
    padding: 10px;

    .filter-icon {
        width: 18px;
        height: 18px;
        fill: white;
    }

    .date-label, .status-label {
        color: white;
    }

    .status-select {
        width: 100%;
        background-color: white;
        border-radius: 4px;
        overflow: hidden;

        label + .MuiInput-formControl {
            margin-top: 8px;
        }

    }

    .u-date-picker2-text{
        width: 100%;
    }

    .MuiOutlinedInput-root {
        background-color: white;
        input {
            outline: unset;
        }
    }

    .MuiGrid-item{
        padding: 5px 5px;

        .date-label {
            padding-top: 0.4rem;
        }
        .status-label {
            padding-top: 0.6rem;
        }
    }

    .search-button {
        min-width: 40%;
        margin-top: 10px;
        margin-left: 30%;
    }
}
