.list-menu {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 0px;
    border-bottom: 1px solid rgb(190, 190, 190);
    // position: relative;
    

    display: flex;
    align-items: center;
    justify-content: space-between;

    .parent-menu-icon {
    }    
    .list-menu-label {
    }
    .chevron-right-icon, .expand-icon {
    }
}

.list-menu-children {
    padding-left: 3ch;
    .list-menu-label {
        font-size: 0.8rem;
    }
}