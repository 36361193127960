@import '../../css/base';

.advanced-product-search-form{
    display: flex;
    align-items: center;
    column-gap: 5px;

    margin-bottom: 10px;

    .filter-icon {
        width: 20px;
        height: 20px;
        fill: rgb(90, 90, 90);
    }
    .shopping-product-search {
        flex-grow: 1;
    }
}

// it is under root element directly
.extra-search-form-wrapper {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 10px 20px 20px 20px;
    background-color: rgba(0,0,0,0.1);
    z-index: 3;
    overflow: scroll;

    .extra-search-form {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        
        margin-top: 13px;
    
        .header{
            // font-weight: bold;
            color: darken($button-primary, 10%);
            padding: 10px;
            margin-bottom: 13px;
            font-size: $font20px;
            text-align: center;
            border-bottom: 1px solid rgb(179, 179, 179);
        }
        .MuiGrid-item {
            padding: 10px 5px;
        }
        .action-buttons {
            display: flex;
            justify-content: space-evenly;
        }
        .extra-search-form-control {
            width: 100%;

            label.MuiInputLabel-formControl{
                font-size: 16px !important;
            }
        }
    }        
}




