.shuyu {
    font-weight: bold;
    border: 1px solid black;
    border-radius: 3px;
    white-space: nowrap;

}
.shuyu.highlight {
    background-color: yellow;
}
