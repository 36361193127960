@import '../css/base';

.login-page {
    width: 100%;
    min-height: calc( 100vh );
    padding-bottom: 20px;

    background-image: linear-gradient(to bottom, white, white 50%,rgb(231, 231, 231));

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    
    .name-en {
        margin-top: 10px;
        color: rgb(40,40,40);
        font-size: $font18px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        .unishell {
            font-style: italic;
        }
    }
    .name-zh {
        margin-top: 20px;
        color: rgb(40,40,40);
        font-size: $font20px;
        // font-weight: bold;
        // font-family: fanzhenliukai;
        // padding-bottom: 10px;
        .unishell {
            background: linear-gradient(to right, #6CA8E9 0%, #6CDA65 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .start-from-here-img {
        max-width: 70%;
        max-height: 40%;
    }

    .notice {
        width: 75% !important;
        // background-color: rgba(189, 189, 189, 0.2);
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        // color: $unishell-gold;
    }

    .language-register {
        padding-top: 15px;
        font-size: $font16px;
        width: 80%;
        height: 24px;
        position: relative;

        .language {
            // color: white;
            display: inline;
            position: absolute;
            left: 0px;
            bottom: 0px;
        }

        .register-account-link {
            // color: white;
            display: inline;
            text-decoration: underline;
            // font-weight: bold;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
    }

    .simple-note{
        box-sizing: border-box;
        width: 80%;
        margin-top: 10px;
    }

    .login-view {
        padding-top: 15px;
        width: 80%;

        .input-wrapper {
            width: 100%;
    
            .login-input {
                width: 100% !important;
            }
            input {
                color: rgb(59, 59, 59);
            }
        }
    }

    .login-btn-wrapper {
        margin-top: 20px;
        .login-btn {
            width: 9em;
            font-size: $font18px;
        }
    }
    .lost-password-wrapper {
        margin-top: 30px;
        .lost-password-btn{
            font-size: $font18px;
            width: 9em;
        }
    }
    .server-url {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        color: black;
        background-color: rgba(255, 166, 0, 0.431);
        font-size: $font12px;
        overflow: hidden;
    }
    
    .notice {
        margin: 20px 10px 20px 10px;
        padding: 20px 10px 20px 10px;
    }


    .input-error-message {
        font-size: 0.8rem;
        color: red;
        padding-bottom: 4px;
    }

    .footer {
        // position: fixed;
        width: 100%;
        text-align: center;
        // left: 0px;
        // bottom: 10px;
    }

    @media only screen and (max-height: 550px) {
        .footer {
            display: none;
        }
    }
}

.login-page.en {
    .login-btn-wrapper {
        .login-btn {
            width: 14rem;
            font-size: $font15px;
        }
    }
    .lost-password-wrapper {
        .lost-password-btn{
            font-size: $font15px;
            width: 14rem;
        }
    }
}

.terms-and-privacy-dialog {
    .MuiDialog-paper {
        margin-left: 10px;
        margin-right: 10px;

        .MuiDialogContent-root {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .MuiDialogActions-root {
        justify-content: space-around;
    }
}
