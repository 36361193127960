@import '../../css/base';

.pagination-select {
    .selected {
        // display: flex;
        // flex-direction: column;
        padding-bottom: 5px;

        svg.MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
    }

    .pagination-list {
        .pagination-item {
            display: flex;
            column-gap: 5px;
            justify-content: space-between;   
            margin-bottom: 10px;
            border-bottom: 1px solid $gray6; 
        }    
    }
}

.pagination-select.expanded{
    .selected {
        border-bottom: 1px solid $gray6; 
    }
}