@import '../../css/base';

.distribute-shop-order-commissions {
    background-color: $paper-gray;

    .summary {
        margin-bottom: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;

        .property-list-widget {
            width: 100%;
        }
    }

    .distribute-shop-order-commissions-main-pane {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: $content-margin-bottom;

        .shop-order-commission{
            background-color: white;
            margin-bottom: 30px;

            font-size: $font14px;   
            font-family: Roboto;
            
            .price {
                font-weight: bold;
                color: $price-color;
            }
            .prod-thumb-img > img{
                width: 50px;
            }

            .property-list-widget {
                @include product-shadow;

                .MuiCheckbox-root {
                    padding: 0px;
                }
            }
        }
    }
}