@import '../../css/base';

.icon-with-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3px;

    .icon {
        width: 20px;
        height: 20px;
    }
    .label {
        font-size: $font13px;
    }
}