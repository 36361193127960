$font12px: 0.75rem;
$font14px: 0.874rem;
$font20px: 1.25rem;
$font13px: 0.8125rem;
$font18px: 1.125rem;
$font28px: 1.75rem;
$font15px: 0.9375rem;
$font22px: 1.375rem;
$font16px: 1rem;
$font10px: 0.625rem;
$font32px: 2rem;

$search-bar-bg: rgb(221, 221, 221);

$primary-color: #1976D2;
$secondary-color: #3087A8;
$unishell-gold: #FFD74A;
// $paper-gray: rgb(158, 158, 158);//#F5F5F5;
$paper-gray: #F5F5F5;
// $paper-gray: #FAFAFA;
// $paper-gray: white;

$link-color: #0047eee8;
$price-color: #810404;
$close-color: #d80000;

$footer-height: 49.03px; // px
// $header-height: 36.69px; // px
$header-footer-height: calc(49.03px + 36.69px); // $footer-height + $header-height
// $content-margin-top: 42px; // enough margin top of content pane so that header does not cover content.
$content-margin-bottom: calc(49.03px + 36.69px + 20px); // enough margin-bottom of content pane so that footer does not cover content.

@mixin mixin-wrap-text {
    overflow-wrap: anywhere;
    word-wrap: break-word;    
    // white-space: pre-wrap; /* CSS3 */    
    // white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    // white-space: -pre-wrap; /* Opera 4-6 */    
    // white-space: -o-pre-wrap; /* Opera 7 */    
    // /* This is the dangerous one in WebKit, as it breaks things wherever */
    // word-wrap: break-all; /* Internet Explorer 5.5+ */
    // word-wrap: break-word; /* Internet Explorer 5.5+ */
    // overflow-wrap: break-word; /* same as previous */
}

$green0: darken(#589608, 10%);
$green1: #589608;
$green2: #7FB500;

$google-green: #34A853;

$acpn_green1: #007770;
$acpn_green2: #0F8341;
$acpn_green3: #129c4d;
$acpn_golden: #E8A141;
$acpn_yellow1: #F6F0A9;

$color1: #58A9F3;
$color2: #6AC8BA;
$color3: #F3B65B;
$color4: #DFDFDF;
$color5: #EAEAEA;
$color6: #F50057;

$address-bg: #b5d1f82d;

$header-green: #1F7D2C;
$search-form-background: linear-gradient(90deg, rgba(31,125,44,1) 0%, rgba(42,162,58,1) 49%, rgba(31,125,44,1) 100%);


$button-primary: darken(#F5B658, 15%); //$google-green; //darken(#589608, 0%);
// $button-secondary: lighten(#6AC8BA, 25%);
$button-secondary: #B5D1F8;
$button-default: #EAEAEA;
$button-alert: lighten(#F50057, 15%);
$button-danger: red;
$button-default-boader: gray;

$button-text-dark: rgb(30, 30, 30);

@mixin product-shadow {
    // -webkit-box-shadow: 0px 3px 3px 3px #d3d3d3; 
    // box-shadow: 0px 3px 3px 3px #d3d3d3;
}

$gray1: rgb(25, 25, 25);
$gray2: rgb(50, 50, 50);
$gray3: rgb(75, 75, 75);
$gray4: rgb(100, 100, 100);
$gray5: rgb(125, 125, 125);
$gray6: rgb(150, 150, 150);
$gray7: rgb(175, 175, 175);
$gray8: rgb(200, 200, 200);
$gray9: rgb(225, 225, 225);
$gray10: rgb(250, 250, 250);

$page-gray: #F3F3F3; // rgb(243,243,243)
