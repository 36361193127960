.service-time {
    border: 1px solid rgba(128, 128, 128, 0.37);
    padding: 4px;
    margin-bottom: 10px;
    border-radius: 6px;

    position: relative;

    .expended-icon {
        position: absolute;
        top: 0px;
        right: 5px;
    }

    .day-of-time {
        display: flex;
        flex-direction: row;
        column-gap: 10px;

    }

    .day-of-week {
        border-bottom: 1px solid rgba(0, 0, 0, 0.178);
    }

    .notes {
        .MuiFormControl-root {
            width: 95%;
        }
    }
}