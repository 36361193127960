@import '../css/base';

$fg-black: rgba(0, 0, 0, 0.8);

.signup-root{
    .signup-page {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;


        .signup-view {
            width: 80%;
            .input-wrapper {
                width: 100%;
        
                .signup-input {
                    width: 100% !important;
                    margin-top: 10px;
                    margin-bottom: 5px;

                    input {
                        padding: 10px 10px 16px 10px;
                    }
                }
        
            }
        }
        .signup-btn {
            margin-top: 10px;
            width: 12em;
        }
        .login-link {
            margin-top: 20px;
            .login-link-btn {
                width: 12em;
            }
        }

        .input-error-message {
            font-size: 0.8rem;
            color: #b71c1c;
            padding-bottom: 4px;
        }

        .terms {
            color: $primary-color;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

.unishell-club-terms-page {
    .return-h1 {
        text-align: center;
        background-color: $primary-color;
        color: white;

        padding-top: 5px;;
        padding-bottom: 5px;;
    }
}

.signup-page.en {
    .signup-btn {
        width: 18em;
    }
    .login-link {
        .login-link-btn {
            width: 18em;
        }
    }
}
