@import '../../css/base';

.shopping-my-health-advice-main-pane {
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: $content-margin-bottom;

    .pagination-list {
        width: 100%;
    }
}

.health-advice-readonly {
    width: 100%;
    margin-bottom: 40px;

    .key-ingredients-paragraph{
        font-size: 0.9rem;
        overflow-wrap: anywhere;
        margin-bottom: 8px;
    }  
    .apply-health-advice-button {
        display: flex;
        justify-content: center;
    } 

    .property-value.prescription-code{
        font-weight: bold;
    }
}

.shopping-my-health-advice-search-form-wrapper {
    background: $search-form-background;
    padding: 10px;
}