@import '../../css/base';

.lost-password-page {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    width: 100%;
    padding-top: 20px;

    .instruction {
        width: 80%;
        padding: 10px 10px 10px 10px;
        color: gray;

    }

    .input-wrapper {
        margin-top: 30px;
        width: 80%;

        .login-input {
            width: 100%;
        }

    }
    button {
        margin-top: 30px;
        width: 8em;
        color: white;
    }

    .secondary-btn {
        background-color: $secondary-color;
    }

    .input-error-message {
        font-size: 0.8rem;
        color: #b71c1c;
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 4px;
    }

}

.lost-password-page.en{
    button {
        width: 13em;
    }
}