@import '../../css/base';

.asset-page {
    width: 100%;
    min-height: 100vh;

    .unis-points {
        padding-left: 20px;
        padding-top: 20px;
        
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        justify-items: center;
        // justify-content: center;
    }
    .currency {
        font-weight: bold;
        font-size: $font16px;
    }
    .points {
        font-size: $font28px;
        font-weight: bolder;

    }
    .points.negative {
        color: red;
    }

    .refresh {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
        
        margin-top: 20px;

        .ubutton {
            min-width: 50%;
        }
    }
}