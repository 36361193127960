@import '../../css//base';

.scan-transfer-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    margin-bottom: $content-margin-bottom;

    .points-view {
        width:100%;
    }
    hr {
        width: 100%;
    }

    .scan-transfer-form {
        flex-grow: 1;
        width: 85%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .scan-transfer-recipient {
            padding-top: 15px;
            width: 100%;

            .label {
                // font-weight: bold;
                display: block;
                margin-bottom: 10px;
            }
            .value {
                display: block;
                margin-left: 1em;
            }
        }

        .points-input-view {
            padding-top: 15px;
            width: 100%;
        }
    
        .memo-input-view {
            padding-top: 20px;
            width: 100%;
            .memo-input {
                width: 100%;
            }
        }

        .password-input-view {
            padding-top: 20px;
            width: 100%;

            .password-input {
                width: 100%;
            }
        
        }

        .input-error-message {
            font-size: 80%;
            color: #b71c1c;
            padding-bottom: 4px;
        }

        .scan-transfer-action {
            margin-top: 20px;
            button {
                width: 10em;
            }
        }
    }
}