@import '../../css/base';

$fg-color: white;
// $fg-color: #005C43;

.top-header-wrapper-class {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 3;
    width: 100%;

    #under-top-header {
        width: 100%;
    }

    .header2 {
        // background: linear-gradient($acpn_green3,#19c462); //
        // background-color: $header-green;
        // background-color: #01B56D;
        // background-color: #008161;
        background-color: #1F7D2C;
        width: 100%;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;

        padding-top: 8px;
        padding-bottom: 8px;
        padding-left:4px;
        padding-right:4px;

        .shopping-cart-icon, .health-advice-icon, .unishell-menu-icon, #page-back-icon{
            width: 20px; 
            height: 20px;
            fill: $fg-color;
        }

        .title {
            color: $fg-color;
            font-size: $font18px;
            font-weight: bold;
            flex-grow: 1;
            text-align: center;
            white-space:nowrap;
            overflow: hidden;
        }

        .right-icons, .left-icons {
            display: flex;
            column-gap: 10px;
            flex-basis: 48px;
            flex-grow: 0;
        }
        .left-icons {
            padding-left: 5px;
            column-gap: 15px;
        }

        .with-red-dot {
            position: relative;

            .red-dot {
                width: 6px;
                height: 6px;
                background-color: red;
                position: absolute;
                top: -2px;
                right: 0px;
                border-radius: 3px;
            }
        }

    }

    .header2.en {
        height: 32.594px;
        .title {
            font-size: $font20px;
        }
    }

    $fg-black: rgba(0, 0, 0, 0.8);

    .header2.no-menu-icon {
        background: unset;
        
        #page-back-icon {
            fill: $fg-black !important;
            background-color: rgb(200, 200, 200);
            border-radius: 12px;
            padding: 2px;
        }
        .title {
            color: $fg-black;
        }
        .unishell-menu-icon {
            visibility: hidden;
        }    
    }
}
