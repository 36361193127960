@import '../../css/base';

.search-users-page {
    width: 100%;

    .search-users-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        width: 100%;
        box-sizing: border-box;
        padding: 10px;

        margin-bottom: $content-margin-bottom;

        .search-user-form {

            .keyword-input{
                width: 100%;
                margin-bottom: 10px;
            }

            .filter {
                margin-top: 10px;
                padding-left: 4px;
                border: 1px solid rgb(180, 180, 180);
                border-radius: 3px;
            }
        }

        .search-user-form-buttons {
            margin-top: 20px;
            button:nth-child(1) {
                margin-right: 20px;
            }
        }

        .user-list {
            margin-top: 20px;
            margin-bottom: 40px;
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            box-sizing: border-box;

            .list-wrapper{
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .user-table {
                margin-bottom: 20px;
                @include product-shadow;
                

                .action-row {
                    display: flex;
                    justify-content: space-around;
                }
            }

            .user-list-footer {
                text-align: center;
                span {
                    margin-left: 5px;
                }
                span.clickable {
                    margin-left: 15px;
                    text-decoration: underline;
                }
            }
        }

        .notice {
            padding-top: 20px;
            font-weight: bold;
        }
    }
}