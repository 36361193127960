@import '../../css/base';

.nutritionist-licence-form {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    background-color: #FFFCEE;
    border-radius: 6px;

    .u-date-picker2-text, .u-date-picker {
        width: 100%;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    label#nutritionist-licence-image-label {
        padding: 4px 8px 4px 8px;
        background-color: $button-primary;
        color: white;
        border-radius: 4px;
    }

    #nutritionist-licence-image-input {
        width: 50%; /* it is critical to limit the size of hidden file input otherwise the layout could be screwed up */
    }

    .licence-img {
        box-sizing: border-box;
        margin-bottom: 10px;
        > img{
            max-width: 100%;
            max-height: 90vw;
        }
    }
    .licence-img.transformed-image{
        border: 2px dashed red;
    }

    .paragraph{
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .error-msg {
        margin-bottom: 8px;
        color: rgb(184, 0, 9);
    }
}