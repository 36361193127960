@import '../../css/base';

.v-equal-size {
    display: flex;
    flex-direction: column;
    align-items: center;

    // .v-equal-size-children {
    //     width: fit-content;
    //     block-size: fit-content;

        .v-equal-size-child {
            flex-basis: 0px;
            flex-grow: 1;
            display: inline-block;
        }    
    // }
}