@import '../../css/base';

.image-uploader {
    .file-input-label {
        background-color: $button-secondary;
        padding: 6px;
        border-radius: 6px;
    }
    .image-rotate{
        margin-bottom: 8px;
        background-color: $gray9;
    }
}