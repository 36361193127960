@import '../../css/base';

.shopping-search-products{
    background-color: $page-gray;

    .shopping-search-products-main-pane {
        width: 100%;
        margin-bottom: $content-margin-bottom;

        .category-button-list{
            font-size: $font13px;
            margin: 10px 10px 10px 10px;
            width: calc(100vw - 20px);

            button {
                background-color: #1976d2;
                border: 1px solid white;
                border-radius: 3px;
                color: white;
                box-shadow: none;
                border: none;
                padding: 4px 8px 4px 8px;
                width: 100%;
            }
        }
        .pagination-list {
            .data-list-wrapper{
                overflow-x: hidden;
                width: calc(100% - 24px) !important; // fix x overflow due to Grid
            }
        }
    }
    .page-size-wrapper{
        padding: 10px;
    }    
}

.shopping-product-search-wrapper {
    background-color: white;
    .simple-shop-search-form {
        padding: 15px;
    }
}

