@import '../../css/base';


.order-dashboard{
    .order-dashboard-content{
        padding: 10px 10px 10px 10px;
        margin-bottom: 38px;
    }

    .order-state {
        margin-top: 5px;        
    }
    .date-range{
        margin-top: 4px;
        label {
            font-size: 0.9rem;
        }
        .u-date-picker {
            max-width: 90%;
        }
    }

    .shopping-order-search-form {
        margin-bottom: 10px;

        .status-select {
            width: 90%;
        }

        .search-button {
            min-width: 40%;
            margin-top: 10px;
            margin-left: 30%;
        }
    }

    .order-dashboard-table {
        width: 100%;
        margin-bottom: 1em;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-collapse: collapse;

        .prod-thumb-img > img{
            width: 50px;
        }
        .property-name{
            font-weight: bold;
            width: 1px;
            white-space: nowrap;
            padding-right: 1em;
        }
        .order-id {
            font-weight: bold;
            font-family: 'Courier New', Courier, monospace;
        }
        .total-price {
            font-weight: bold;
            color: $price-color;
        }

        .status-PAID {
            display: inline-block;
            background-color: green;
            color:white;
            padding: 3px 3px 3px 3px;
            border-radius: 3px;
            font-weight: bold;
        }
        .status-SHIPPED {
            display: inline-block;
            background-color: purple;
            color:white;
            padding: 3px 3px 3px 3px;
            border-radius: 3px;
            font-weight: bold;
        }
        .status-DELIVERED {
            display: inline-block;
            background-color: black;
            color:white;
            padding: 3px 3px 3px 3px;
            border-radius: 3px;
            font-weight: bold;
        }

        thead th {
            background-color: rgba(128, 128, 128, 0.5);
        }
        tr.basic-info-tr {
            background-color: rgba(255, 255, 0, 0.2);
        }
        tr{
            border: 1px solid rgba(0, 0, 0, 0.2);
            td, th {
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 6px 8px 6px 8px;
                font-size: $font14px;   
                font-family: Roboto;
            }
        }  
        
        .product-list{
            display: flex;
            column-gap: 20px;
            row-gap: 10px;
            flex-wrap: wrap;

            .product {
                display: flex;
                flex-direction: column;

                .product-image{
                    flex-grow: 1;
                }
            }
        }
        .address-col{
            display: flex;
            column-gap: 20px;
            flex-wrap: wrap;
        }

        .add-courier-track-number{
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

        .shipping-record{
            display: flex;
            align-items: center;
            column-gap: 10px;
            background-color: bisque;

            .shipping-record-status {
                min-width: 5em;
            }
        }

        .shipping-record-row{
            display: flex;
            align-items: center;
            column-gap: 10px;
            row-gap: 5px;
            flex-wrap: wrap;
        }
    }
}