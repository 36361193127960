@import '../../css/base';

.contact-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;
    row-gap: 30px;

    > p {
        font-weight: bolder;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .MuiFormControl-root.MuiTextField-root{
        width: 100%;
    }

    .ubutton {
        width: 50%;
    }
}