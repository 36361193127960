@import '../../css/base';

.shopping-product-detail-main-pane {
    font-family: Roboto, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100vw - 20px);
    padding-left: 10px;
    box-sizing: border-box;

    .product-name {
        font-size: $font18px;
    }

    .product-image-n-headers{
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        .product-image {
            box-sizing: border-box;
            padding-right: 20px;
            max-width: calc(30vw);
            max-height: calc(40vw);
            float: left;
        }
        .product-header {
            padding-left: 4px;

            .product-price{
                font-weight: bold;
                color: rgb(172, 0, 0);
            }

            .currency-label{
                font-size: 0.9rem;
            }

            .product-header-table {
                font-size: $font14px;
                td:nth-child(1){
                   padding:4px 10px 4px 0px;
                   font-weight: bold;
                }
                td:nth-child(2){
                    color:rgba(0, 0, 0, 0.746);
                }
            }
        }

        .special-price-ribbon{
            font-weight: bold;
            color:red;
            position:absolute;
            top: 0px;
            left: 0px;
            transform: rotate(-45deg);
        }
    }
    .health-advice{
        display: flex;
        flex-direction: column;
        align-items: center;
    
        margin-bottom: 20px;
        .health-advice-message {
            font-weight: bold;
            font-size: $font14px;
        }
    }

    .accordion-wrapper{
        width: 100%;
        margin-bottom: 3px;
    }
    .accordion-wrapper.warning, .accordion-wrapper.field_zhuanyejianyi {
        .MuiAccordion-root {
            background-color: #3695ee;
            color: white;
    
            .MuiIconButton-label svg {
                fill: white;
            }
        }
    }
    .accordion-wrapper.field_gongxiaochengfen{
        .MuiAccordion-root {
            background-color: #0081A7;
            color: white;
    
            .MuiIconButton-label svg {
                fill: white;
            }
        }

        .MuiCollapse-wrapper{
            background: linear-gradient(180deg, #0081A7 0%, #00AFB9 100%);
            color: white;

            .property-list-widget {
                border-bottom: 1px solid whitesmoke !important;
            }
            .property-list-widget:last-child{
                border-bottom: unset !important;
            }
        }
    }

    .MuiAccordion-root{
        width: 100%;

        .MuiAccordionSummary-root{
            font-size: $font15px;
        }

        .accordion-detail-product{
            font-size: $font15px;
            line-height: 1.7;

            .product-field-group{                
                .field-label{
                    font-weight: bold;
                    font-size: 0.9rem;
                }
                .field-label::before{
                    content: '• ';
                }
                .simple-product-field{
                    padding-left: 1em;
                }

                .product-field-group-item.short{
                    display: flex;
                    align-items: center;
                }
            }

            .product-field-table {
                width: 100%;
                font-size: $font14px;
                .property-list-widget {
                    width:100%;
                    margin-bottom: 10px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                }
                .property-list-widget:nth-last-child(1){
                    border-bottom: none;                    
                }
            }
        }
    }
}