@import '../../css/base';

.my-profile-page {
    width: 100%;

    .my-profile-menu-list {
        padding-bottom: $content-margin-bottom;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;


        .logout-button {
            margin-top: 40px;
            min-width: 12ch;
        }
    }
}

.my-profile-page.en {
    .my-profile-menu-list {
        .list-menu-label {
            font-size: $font16px;
        }
    }
}

.delete-account-dialog {
    .MuiDialogActions-root {
        justify-content: space-around;
    }
}