@import '../../css/base';

.pickup-locations {
    background-color: $paper-gray;

    .pickup-location-content {
        margin-bottom: $content-margin-bottom;
        padding: 10px;
    }

    .pickup-location {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid $gray6;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: white;

        line-height: 1.5;
    }
}