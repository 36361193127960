@import '../../css/base';

.read-more-wrapper {
    .content {
        overflow: hidden;
    }
    .read-more-button {
        // margin-top: 20px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 0.5px solid rgb(200, 200, 200);
        font-size: $font14px;
        color: rgb(131, 131, 131);
    }
}