@import '../../css/base';

$item-border: 1px solid rgba($color: #000000, $alpha: 0.2);

.shopping-cart-product {
    display: grid;
    grid-template-columns: [first] 20% [second] 1fr;

    .product-image {
        grid-column-start: 1;
        grid-column-end: 2;

        padding-top: 8px;
        img {
            max-width: 90%;
        }
    }

    .product-summary {
        grid-column-start: 2;
        grid-column-end: 3;

        .product-summary-table {
            width: 95%;
        }
    }
    .product-actions {
        grid-column-start: 1;
        grid-column-end: 3;

        display: flex;
        align-items: center;
        justify-content: space-evenly;

        // border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
    }
    .price {
        font-weight: bold;
        color: $price-color;
    }
}

.shopping-cart-product.standalone{
    border: $item-border;
    border-radius: 8px;
    padding: 8px 8px 8px 8px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1em;
}

.shopping-cart-health-advice{
    border-radius: 8px;
    border: $item-border;
    padding: 8px 8px 8px 8px;
    box-sizing: border-box;
    margin-bottom: 1em;

    .shopping-cart-health-advice-table {
        width: 100%;

        .shopping-cart-product{
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
            padding-bottom: 4px;
            margin-bottom: 4px;
        }

        tr.health-card-code {
            border-bottom: 1px solid black;
            font-weight: bold;
            color: darken($google-green, 10%);
            >td {
                padding: 8px 8px 8px 8px;
            }
        }
    
        .health-advice-actions {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }   
    .price {
        font-weight: bold;
        color: $price-color;
    } 
}

.shopping-cart-summary {
    border-radius: 6px;
    margin-bottom: 1em;

    .property-value {
        text-align: right;
    }

    .discount {
        color: green;
        font-weight: bold;
    }
    .fee {
        font-weight: bold;
    }

    .price {
        font-weight: bold;
        color: $price-color;
    }
}

.use-coupon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        padding: 4px 8px 4px 8px;
    }        
}


