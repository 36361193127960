@import '../../css/base';

.commission-page {
    width: 100%;

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 20px;
        padding-top: 20px;
        margin-bottom: $content-margin-bottom;

        > .property-list-widget{
            // @include product-shadow;
            background-color: $paper-gray;
        }

        .brief-table {
            width: 96%;
            margin-bottom: 20px;
            input {
                width: 95%;
                border-radius: 4px;
                border-color:gray;
                border-width: 1px;
                border-style: solid;
            }
        }
        .commision-record-list-footer {
            margin-top: 15px;
            text-align: center;
            span {
                margin-left: 5px;
            }
            span.clickable {
                margin-left: 15px;
                text-decoration: underline;
            }
        }

        .commission-record  {
            margin-top: 20px;
            width: 96%;

            .property-list-widget{
                @include product-shadow;
            }
        }

        .notice {
            width: 130px;
            padding: 10px 10px 10px 10px;
            margin: auto;
        }

        .submit-button {
            margin-top: 40px;
            button {
                width: 10em;
            }
        }
    }
}