@import '../../css/base';

.image-rotate {
    box-sizing: border-box;
    background-color: $gray10;

    .image-wrapper {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        // border: 1px dashed rgb(194, 194, 194);
        box-sizing: border-box;

        img {
            width: 100%;
        }
    }

    .rotate-actions {
        display: flex;
        padding: 2px 20px;
        justify-content: space-evenly;

        border-left: 1px dashed rgb(194, 194, 194);
        border-right: 1px dashed rgb(194, 194, 194);
        border-bottom: 1px dashed rgb(194, 194, 194);

        font-size: $font16px;

        .label {
            padding: 4px;
            border: 1px solid $gray7;
            border-radius: 4px;
        }
    }
}