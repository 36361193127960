@import '../../css/base';

.shopping-my-nutritionist .shopping-my-nutritionist-main-pane {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-bottom: $content-margin-bottom;

    .set-nutritionist-time {
        font-size: 0.8rem;
        color:rgba(0, 0, 0, 0.589);
    }

    .nutritionist-detail {
        width: calc(100vw - 20px);

        .property-list-widget, .service-time {
            margin-top: 20px;
        }
    }

    button.scan-nutritionist-qrcode-button, a.goto-nutritionist-list {
        margin-top: 20px;
        margin-bottom: 20px;        
    }

    hr {
        width: calc(100vw - 20px);
        margin-top: 20px;
        margin-bottom: 20px;        
    }

    #unset-nutritionist-button {
        margin-top: 20px;
    }
}

.cancel-nutritionist-dialog {
    .MuiTextField-root {
        width: 100%;
        box-sizing: border-box;
    }

    .MuiDialogActions-root {
        .ubutton:first-child{
            margin-right: 20px;
        }
    }
}