@import '../../css/base';

.payment-success {
    margin: 20px 20px 20px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .success-icon {
        width: 32px;
    }

    .header {
        font-weight: bold;
        font-size: 1.2rem;
    }

    table {
        width: 100%;

        td {
            padding: 12px 8px 12px 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }

        td:nth-child(1){
            font-weight: bold;
        }
        td:nth-child(2){
            font-size: 0.9rem;
        }

        .amount {
            font-weight: bold;
            color: $price-color;
        }
    }
}