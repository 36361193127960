@import '../../css/base';

.image-overlay{
    position: fixed;
    top: 0px; left: 0px; right:0px; bottom: 0px;
    z-index: 100;
    background-color: rgba(black, 0.75);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-around;
   
    .the-image {
        max-width: 100%;
        max-height: 80vh;
        box-sizing: border-box;
        // border: 1px dashed rgba(255, 255, 255, 0.3); 
        background-color: white;
    }
    .close-icon{
        color: $close-color;
        position: fixed;
        right: 20px;
        top: 20px; 
        z-index: 101; 
    }
    .arrow-left-icon {
        width: 24px;
        fill: white;
    }
    .arrow-right-icon {
        width: 24px;
        fill: white;
    }

}