.MuiDialog-root.confirm-dialog{
    #alert-dialog-title {
        border-bottom: 1px solid rgba(3, 3, 3, 0.2);
        h2 {
            text-align: center;
        }
    }

    .MuiDialogContent-root {
        border-bottom: 1px solid rgba(3, 3, 3, 0.2);
    }

    .MuiDialogActions-root {
        display: flex;
        justify-content: space-evenly;
    }
}