@import '../../css/base';

.nutritionist-detail{
    .service-time p {
        font-weight: bold;
    }
    .address {
        line-height: 1.5;
    }
    table {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-collapse: collapse;

        tr, td {
            border: 1px solid rgba(0, 0, 0, 0.3);
        }

        td{
           padding: 4px 8px 4px 8px; 
        }
        td:nth-child(1){
            font-weight: bold;
            min-width: 5em;
        }
        td:nth-child(2){
            color: rgba(0, 0, 0, 0.7);
        }
    }
    .grid-images{
        width: 100%;
        margin-top: 1em;
    }

    .licence-image {
        width: 100%;
    }  
    .qrcode-image {
        width: 100%;
    } 
}