.toast-snackar-success{
    .MuiSnackbarContent-root {
        background-color: #43A047;
    }
}

.toast-snackar-info{
    .MuiSnackbarContent-root {
        background-color: #1976D2;
    }
}

.toast-snackar-warning{
    .MuiSnackbarContent-root {
        background-color: #FFA000;
    }
}

.toast-snackar-error{
    .MuiSnackbarContent-root {
        background-color: #D32F2F;        
    }
}