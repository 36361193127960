@import '../../css/base';

.settings-page {
    display: flex;
    flex-direction: column;
    width: 100%;

    .user-settings-panel {
        width: 100%;
        flex-grow: 1;
        margin-bottom: $content-margin-bottom;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: center;

        .property-wrapper {
            margin-top: 10px;
            width: 100%;
            box-sizing: border-box;
            padding-left: 10px;
            padding-right: 10px;                       
        }

        .MuiPaper-root {
            width: 100%;
        }

        button {
            min-width: 10ch;
        }
    }

    .upgrade-btn {
        padding: 20px 10px 10px 20px;
    }

    .upgrade-info {
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        width: calc(100vw - 20px);

        label {
            font-size: $font14px;
        }

        #download-url {
            width: calc(100% - 14px); /* textarea has 1px border and 2px padding */
            font-family: monospace;
        }

        .download-div {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            label, button {
                margin-bottom: 20px;
            }
        }
    }

    .user-name .MuiTextField-root, .phone-number .MuiTextField-root {
        width: 100%;
    }
    .edit-buttons {
        margin-top: 5px;
        display: flex;
        justify-content: start;
        column-gap: 10px;
    }
    .edit-buttons.edit {
        justify-content: space-evenly;
    }
}

.user-settings-panel.en {
   .upgrade-info .download-div button {
       font-size: $font14px;
       width: 13rem;
   }
}