@import '../../css/base';

.manual-transfer-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-bottom: $content-margin-bottom;

    .points-view {
        width:100%;
    }
    hr {
        width: 100%;
    }

    .manual-transfer-form {
        width: 100%;
        flex-grow: 1;
        font-size: $font16px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .points-input-view {
            padding-top: 15px;
            width: 85%;
        }
    
        .recipient-input-view {
            padding-top: 20px;
            width: 85%;
        }

        .memo-input-view {
            padding-top: 20px;
            width: 85%;

            .memo-input {
                width: 100%;
            }
        
        }
    
        .submit-button {
            margin-top: 30px;
            button {
                width: 10em;
            }
        }
    }
}
