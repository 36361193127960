.shopping-create-health-advice{
    .shopping-create-health-advice-content {
        .key-ingredient-checkbox {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 10px;
        }
        .key-ingredient-checkbox:last-child {
            border-bottom: unset;
            margin-bottom: unset;
        }
    }
}
