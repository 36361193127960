.nutritionist-client-form {
    width: 100%;

    .MuiFormControl-root{
        width: 100%;
    }

    .error-msg {
        margin-bottom: 1em;
        color: rgb(184, 0, 9);
    }
}