@import '../../css/base';

.ubutton {
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    border-width: 0px;
}

.ubutton.medium {
    padding: 6px 16px;
    font-size: 1rem;
    border-radius: 6px;
}

.ubutton.small {
    padding: 2px 8px;
    font-size: 0.875rem;
    border-radius: 6px;
}

// .ubutton.contained {
//     box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);    
// }

.ubutton.contained.primary {
    background-color: $button-primary;
    color: white;
}
.ubutton.contained.alert {
    background-color: $button-alert;
    color: white;
}
.ubutton.contained.danger {
    background-color: $button-danger;
    color: white;
}


.ubutton.contained.secondary {
    background-color: $button-secondary;
    color: $button-text-dark;
}

.ubutton.contained.default {
    background-color: $button-default;
    color: $button-text-dark;
}

.ubutton.contained.disabled{
    background-color: rgb(190, 190, 190) !important;
    color: white !important;
}

.ubutton.outlined {
    background-color: white;
}

.ubutton.outlined.primary {
    border: 1px solid $button-primary;
    color: $button-text-dark;
}
.ubutton.outlined.alert {
    border: 1px solid $button-alert;
    color: $button-alert;
}
.ubutton.outlined.danger {
    border: 1px solid $button-danger;
    color: $button-danger;
}


.ubutton.outlined.secondary {
    border: 1px solid $color2;
    color: $button-text-dark;
}

.ubutton.outlined.default {
    border: 1px solid $button-default-boader;
    color: $button-text-dark;
}
.ubutton.outlined.disabled {
    border: 1px solid $color5;
    color: $color5;
}