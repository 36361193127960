.shopping-switch-currency {
    min-height: 100vh;
    
    .simple-note {
        width: 100%;
        box-sizing: border-box;
    }
    .currency-header {
        text-align: center;
    }
    .unis-currency-symbol {
        height: 1em;
        width: auto;
    }

    .UNIS-currency-grid-item{
        display: flex;
        align-items: center;
    }

}