.property-list-widget {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    .property-list-row {
        width: 100%;
        width: 100%;
        display: flex;

        .property-name-wrap {
            padding: 8px 5px 8px 5px;
        }
        .property-name {
            white-space: nowrap;
            font-weight: bold;
            // overflow-x: hidden;
        }
        .property-value-wrap {
            /* 
              A flex item cannot be smaller than the size of its content along the main axis by default.
              so we need to override it. https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
             */
            min-width: 0px;
            padding: 8px 5px 8px 5px;
            flex-grow: 1;

            .property-value {
                width: 100%;
                overflow-wrap: anywhere;
                word-wrap: break-word;    
            }
        }
    }
    .property-list-row-merge-cols {
        .property-name-wrap {
            padding: 5px 5px 5px 5px;
        }
        .property-name {
            white-space: nowrap;
            font-weight: bold;
            // overflow-x: hidden;
        }
        .property-value-wrap {
            padding: 5px 5px 5px 5px;

            .property-value {
                width: 100%;
                overflow-wrap: anywhere;
                word-wrap: break-word;    
            }
        }
    }
}

$border: 1px solid rgba(0, 0, 0, 0.20);

.property-list-widget.show-border {
    border: $border;
    border-radius: 6px;

    .property-list-row {
        .property-name-wrap {
            border-bottom: $border;
            border-right: $border;
        }
        .property-value-wrap {
            border-bottom: $border;
        }
    }
    .property-list-row-merge-cols {
        border-bottom: $border;
    }

    .property-list-row.last {
        .property-name-wrap {
            border-bottom: none;
        }
        .property-value-wrap {
            border-bottom: none;
        }
    }

    .property-list-row-merge-cols.last {
        border-bottom: none;
    }
}
