@import '../../css/base';

.search-products-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .search-products-page-content {
        margin-bottom: $content-margin-bottom;
        padding-left: 10px;
        padding-right: 10px;
    }

    .search-form {
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: $search-bar-bg;
        .search-input {
            padding-left: 5px;
            width: calc(100vw - 120px);
        }
    }
    .extra-search-form {
        width: calc(100vw - 40px);
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        background-color: $search-bar-bg;

        .extra-search-form-control {
            width: calc(100%);
        }

    }
    .pagination-list {
        margin-bottom: 50px;
    }

    .product-list {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0px 10px 0px 10px;

        .product-summary-table{
            margin-top: 10px;
            margin-bottom: 10px;

            .product-summary-row {
                border-radius: 6px;
                margin-bottom: 20px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @include product-shadow;

                padding: 8px;
                display: flex;

                .product-brief-name {
                    color: $link-color;
                    text-decoration: underline;;
                }
                .product-brief-paragraph {
                    margin-top: 14px;
                    margin-bottom: 14px;
                    line-height: 1.3;
                }
                .product-thumbnail {
                    min-width: 48px;
                    max-width: 128px;
                    width: 20vw;
                    padding-right: 10px;
                    box-sizing: border-box;
                }
            }
        }

        .multiline-field-label-text{
            padding-top: 7px;
        }
        .multiline-field-label{
            padding-top: 5px;
        }
    }

    .product {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc( 100vw - 20px );

        .product-title {
            margin-bottom: 0px;
        }
        img.product-image {
            width: 100%;
            padding-top: 20px;
            max-width: 128px;
        }

        .product-field-paper {
            width: 100%;
            margin-top: 10px;
        }

        .multiline-field {
            width: 100%;
            margin-top: 0.3em;
            .multiline-field-label {
                display: flex;
                align-items: center;
                padding-left: 5px;

                label {
                    padding-left: 5px;
                    font-weight: bold;
                }
            }
            .multiline-field-label-text{
                padding-top: 7px;
                padding-left: 1em;
                color: rgb(97, 97, 97);
                font-size: $font13px;

                .line {
                    margin-bottom: 10px;
                }
            }
            .multiline-field-label-text.font-large {
                font-size: $font20px !important;
            }
        }
        .multiline-field.highlight {
            .multiline-field-label-text{
                background-color: yellow;
            }
        }
        .multiline-field.warn {
            padding-top: 5px;
            // background-color: #fff3cd;
            color: rgb(255, 166, 0);
        }

        .ingredient-table {
            width: 100%;
            margin-top: 10px;
            .label-wrapper {
                label {
                    font-weight: bold;
                }
            }

            table {
                margin-top: 10px;
                td {
                    padding-left: 5px;
                    padding-right: 0px;
                }
                td:nth-child(1) {
                    width:95px;
                }
                .ingredient-tr-property{
                    min-width: 5em;
                }
                .ingredient-tr-value {
                    overflow-wrap: anywhere;
                }
            }
        }
        .action-buttons{
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-evenly;            
        }
    }

    .shuyu {
        font-weight: bold;
        border: 1px solid black;
        border-radius: 3px;
        white-space: nowrap;

    }
    .shuyu.highlight {
        background-color: yellow;
    }
}

svg.search-products-page-return-icon {
    width: 24px !important;
    height: 24px !important;
    path {
        fill: $unishell-gold !important;
    }
}