@import '../../css/base';

$fg-color: rgb(46, 46, 46);

.main-menu {
    position: fixed;
    left:0px;
    bottom: 0px;
    width: 100%;
    z-index:2;
    background-color: white;

    box-shadow: 0px 0px 2px 2px rgba(153,153,153,0.3);
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    // border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 4px;
    padding-bottom: 2px;
    // background-color: whitesmoke;

    .menu-item-div {
        overflow: hidden;
        padding: 2px 4px 2px 4px;
        block-size: fit-content;

        .menu-item svg{
            fill: $fg-color;
        }
        .menu-item.active svg {
            fill: $green2;
        }
    }
}
.non-prod-indicator {
    position: fixed;
    z-index: 2;
    bottom: 2px;
    left: 2px;
    font-size: $font18px;
    font-weight: bold;
    color: red;
    border: 1px solid darken($unishell-gold, 30%);
}
