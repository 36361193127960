@import '../../css/base';

.shopping-create-health-advice{
    min-height: 100vh;
    
    .simple-note {
        width: 100%;
        box-sizing: border-box;
    }



    //   background-color: #f5f5f5;
     .shopping-create-health-advice-content{
         display: flex;
         flex-direction: column;
         align-items: center;
         padding-left: 10px;
         padding-right: 10px;
         padding-bottom: $content-margin-bottom;

         .search-product-btn-wrapper {
             margin-top: 20px;
         }
         .client-of-nutrtionist{
            width: 100%;
            margin-bottom: 1em;
    
            td {
                padding: 4px 8px 4px 8px;

                .create-health-advice{
                  font-weight: bold;
                }
            }
    
        }

        #health-advice-title{
            width: 100%;

            .MuiTextField-root{
                width: 100%;
            }
        }

        #health-advice-description{
            width:100%;
            margin-top: 1em;
            box-sizing: border-box;

            textarea {
                width: 100%;
                box-sizing: border-box;
                border-color: rgba(0, 0, 0, 0.3);
            }
        }

        #health-advice-expiry{
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 1em;

            .MuiTextField-root{
                width: 5em;
                margin-left: 1em;
                margin-right: 1em;

                input {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }

        .pagination-list {
            .data-list-wrapper{
                overflow-x: hidden;
                width: calc(100% - 24px) !important; // fix x overflow due to Grid
            }        
        }

        .add-product-to-health-advice{
            margin-top: 5px;
        }

        .products-in-health-advice{
            margin-top: 5px;
            width: 100%;
            font-size: $font14px;
            padding-bottom: 1em;

            .prod-in-advice {
                li {
                    margin-bottom: 1ch;
                }
            }
            
            .prod-in-advice-actions {
                display: flex;
                justify-content: space-evenly;
                margin-top: 5px;
            }
        }
        .table-footer {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            column-gap: 20px;
        }
        
        .h-table-footer {
            margin-top: 1em;

            .h-equal-size-child.initialized{
                .ubutton {
                    width: 100%;
                }    
            }
        }
        .v-table-footer {
            margin-top: 1em;

            .ubutton {
                min-width: calc(60vw);
            }    

            .v-equal-size-child.initialized{
                .ubutton {
                    width: 100%; // stretch
                }    
            }
        }
    }

    .add-product-detail-to-health-advice{
        margin-bottom: 1em;
    }

    .error-msg {
        margin-bottom: 1em;
        color: rgb(184, 0, 9);
    }

    .action-buttons {
        .MuiGrid-item {
            padding: 10px 5px 0px 5px;

            button {
                width: 100%;
            }
        }
    }
}

.create-health-advice-under-header{
    width: 100%;
    background-color: white;

    .advanced-product-search-form-wrapper {
        width: 100%;
        padding-left: 10px;
        box-sizing: border-box;
    
        .advanced-product-search-form{
            margin-top: 5px;
            width: 100%;
        }    
    }
    
    .subtitle-header {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    
        display: flex;
        justify-content:space-evenly;
        align-items: center;
    
        .subtitle {
            // font-weight: bold;
            font-size: 1.25rem;
        }
    
        .MuiMobileStepper-dots {
            margin-left: 10px;
            margin-right: 10px;
        }
        .step {
            text-decoration: underline;
            font-size: 0.9rem;
        }
        .step.hide-step {
            color: $gray7;
            // visibility: hidden;
        }
    }
    
}

