@import '../css/base';

.annoucement-wrapper{
    position: fixed;
    top: 0px; left: 0px; right: 0px; bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);

    padding: 40px 20px;

    z-index: 3;


    .annoucement-panel {
        width: 100%;
        height: 100%;
        background-color: white;
        box-sizing: border-box;
        
        // border: 1px solid white;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;

        overflow-y: auto;
        padding-bottom: 10px;

        .login-success {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            background-color: $google-green;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding-top: 15px;
            padding-bottom: 15px;

            color: white;
            .check-icon {
                width: 1rem;
                height: 1rem;
                fill: white;
            }
        }

        .annoucement {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .annouce-header {
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-weight: bolder;

                .annouce-icon {
                    width: 20px;
                    height: 20px;
                    
                    path {fill: $google-green};
                }
            }

            .annouce {
                font-weight: lighter;
                font-size: $font14px;
                line-height: 200%;
                padding: 15px 20px 15px 20px;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            width: 70%;

            .simple-note{
                margin-bottom: -10px;
            }

            .ubutton {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 10px;
                min-width: 50%;

                .icon{
                    width: 16px;
                    height: 16px;
                    fill: white;
                }
            }
        }
    }
}