@import '../../css/base';

@mixin icon-size {
    width: 28px;
    height: 28px;
}

$avatar-margin-top: 10px;
$avatar-size: 25vw;
// $green-part-height: calc(50vw * 0.8 + 10px);
$avatar-max-size: 128px;
// $green-part-max-height: calc(64px * 0.8 + 10px);

@mixin section-common {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    box-sizing: border-box;

    display: flex;
    column-gap: 4px;
    align-items: center;
}


.shopping-search-nutritionist {
    background-color: $page-gray;

    .shopping-search-nutritionist-main-pane{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $content-margin-bottom;
        width: 100%;
        box-sizing: border-box;
        padding-left:10px;
        padding-right: 10px;
        padding-top: 1em;

        .pagination-list {
            width: 100%;
            box-sizing: border-box;
        }

        .MuiGrid-item {
            padding: 6px;
        }

        .nutritionist-brief2{
            width: 100%;
            height: 100%;
            box-sizing: border-box;

            border: 1px solid $gray8;
            border-radius: 8px;
            
            padding-bottom: 10px;
            background-color: white;

            display: flex;
            flex-direction: column;

            .avatar-section {
                // border-top: 1px solid rgb(214, 214, 214);
                width: 100%;
                box-sizing: border-box;

                display: flex;
                padding-bottom: 10px;
                padding: 6px;
                column-gap: 8px;

                .nutritionist-avatar{
                    width: $avatar-size;
                    height: $avatar-size;

                    max-width: $avatar-max-size;
                    max-height: $avatar-max-size;
                }

                .nutritionist-name {
                    font-weight: bold;
                    color: $acpn_green1;
                    padding-bottom: 0.5rem;
                    // font-size: 1.2rem;
                }
                .titles {
                    font-size: $font14px;
                    font-style: italic;
                    padding-bottom: 0.5rem;
                }

            }

            .institution {
                @include section-common;
            }
            .gender-section {
                font-size: $font14px;
                // @include section-common;
                // margin-left: unset;
                // margin-right: unset;
                svg {
                    @include icon-size;
                }
            }
            .phone-section {
                @include section-common;
                svg {
                    @include icon-size;
                }
            }
            .email-section {
                @include section-common;
                svg {
                    @include icon-size;
                }
            }
            .language-section {
                width: 100%;
                @include section-common;
                line-height: 1.5;
                font-size: $font14px;
                svg {
                    @include icon-size;
                }
            }
            .website-section {
                @include section-common;
                svg {
                    @include icon-size;
                }
            }

            .address-section {
                display: flex;
                column-gap: 12px;
                // margin-top: 15px;
                @include section-common;
                align-items: flex-start;
    
                .address-icon {
                    @include icon-size;
                }
                .address {
                    flex-grow: 1;
                    // background-color: $gray10;
                    padding-left: 5px;
                    border-radius: 6px;
                    // border: 1px solid rgb(230, 230, 230);
                    font-size: $font14px;
    
                    .address-line {
                        line-height: 1.5;
                    }    
                }
            }
    
            .action-section {
                @include section-common;
                display: flex;
                justify-content: space-around;
            }
        }

        .nutritionist-brief {
            width: calc(100vw - 30px);
            margin-bottom: 40px;
            @include product-shadow;

            .green-check-icon{
                color: green;
                font-weight: bolder;
                font-size: 1.25rem;
            }

            .property-value.action-button{
                display: flex;
                justify-content: center;
            }
        }
    }
}

.search-nutritionist-form{
    padding: 8px;
    background: $search-form-background;
    
    .keyword-input {
        display: flex;
        column-gap: 20px;
        align-items: center;

        .filter-icon {
            width: 20px;
            height: 20px;
            fill:white;
        }
        .shopping-product-search {
            box-sizing: border-box;
            flex-grow: 1;
        }    
    }
    .sex-filter {
        width: 100%;
        display: flex;
        align-items: center;
        color: white;

        .label {
            margin-right: 10px;
            min-width: 3rem;
            // font-weight: bolder;
        }
        .MuiCheckbox-root .MuiSvgIcon-root {
            fill: white;
        }
    }    
}

.nutritionist-brief2-expertice{
    .content, .content-in-dialog {
        width: 100%;
        box-sizing: border-box;
        line-height: 1.5;
        padding-left: 8px;
        padding-right: 8px;
        font-size: $font14px;  
        
        // white-space: pre-wrap;
    }
    .content{
        max-height: 5.5rem;
    }
    .read-more-button {
        margin-left: 10px;
    }
}
