@import '../../css/base';

.list-editor {
    display: flex;
    flex-direction: column;
    position: relative;

    .title{
        padding: 8px;
    }
    .list-editor-icon {
        width: 18px;
        height: 18px;
    }
    .list-editor-icon.editor-icon {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .action-icons, .insert{
        display: flex;
        justify-content: space-around;
    }
    .list-item {
        width: 100%;
    }
}