@import '../../css/base';

.update-prod-index-content {
    margin-top: 20px;
    margin-bottom: $content-margin-bottom;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}