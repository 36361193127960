@import '../../css/base';

.search-client-health-advice-form {
    width: 100%;

    .health-advice-state-wrapper {
        display: flex;
        align-items: center;
        column-gap: 10px;

        .label {
            color: white;
        }
        .status-select {
            flex-grow: 1;
            margin-top: 10px;
            margin-bottom: 10px;
    
            .MuiOutlinedInput-root {
                background-color: white;
            }
        }    
    }

}

.search-client-health-advice-form.multi-cols{
    .MuiGrid-item {
        padding-right: 10px;
    }
}