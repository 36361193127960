@import '../../css/base';

.shopping-product-search{
    border-radius: 19px;
    // background-color: $search-bar-bg;
    background-color: white;                
    display: flex;

    .MuiInputBase-root.search-input{
        margin-left: 10px;
        margin-right: 5px;
        flex-grow: 10000;
        border-radius: 7px;
    }
    .search-button{
        padding: 6px;
    }
}