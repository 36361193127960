@import '../../css/base';

.edit-nutritionist {
    .text-input {
        width: 100%;
        margin-top: 20px;

        .MuiFormControl-root {
            width: 100%;
        }
    }
    .error-msg {
        margin-bottom: 8px;
        color: rgb(184, 0, 9);
    }

    .add-service-time-button {
        display: inline-block;
        margin-top: 5px;
        margin-left: 1em;
    }

    .list-editor.social-medias, .list-editor.testimonials{
        margin-bottom: 10px;
        border: 1px solid $gray6;
        border-radius: 8px;
        padding: 6px;
        
        .list-item {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }
    .list-editor.social-medias {
        .MuiTextField-root {
            width: 100%;
            margin-top: 10px;
        }
    }

    .list-editor.testimonials {
        .MuiTextField-root {
            width: 100%;
            margin-top: 10px;
        }
    }

    .markdown-editor.resume{
        // margin-left: 8px;
        border: 1px solid $gray6;
        border-radius: 8px;
        padding: 6px;
    }
    .list-editor.service-time-list{
        border: 1px solid $gray6;
        border-radius: 8px;
    }

    .list-editor.edit{
        border-color: red;
        border-style: dashed;
    }
}